import React, {useEffect, useRef, useState} from "react";
import '../../../styles/list.css';

import {Col, Container, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome} from "@fortawesome/free-solid-svg-icons";
import {routesEnum} from "../../../../enums/routesEnum";

import {IError} from "../../../../types/IError";
import {useNavigate} from "react-router-dom";
import {Toast} from "primereact/toast";
import {actionBtn, FormSplitButton} from "../../../../components/ui/buttons/FormSplitButton";
import {actionSplitBtnEnum} from "../../../../enums/actionSplitBtnEnum";
import {useTranslation} from "react-i18next";
import {LottieLoadingSuccessfully} from "../../../../components/ui/animations/LottieLoadingSuccessfully";
import {Breadcrumb} from "../../../../components/ui/breadcrumb/Breadcrumb";
import {ToggleButton} from "primereact/togglebutton";
import {serviceTrustedUserCreate, serviceWithoutTrustedUserList} from "../../../../services/users/trustedUserService";
import {IWithoutTrustedUser} from "../../../../types/users/ITrustedUser";
import {InputMask} from "../../../../components/ui/form/inputMask/InputMask";
import {MultiSelect} from "../../../../components/ui/form/multiSelect/MultiSelect";
import {MultiSelectFooterPanel} from "../../../../components/ui/form/multiSelect/MultiSelectFooterPanel";
import {InputPassword} from "../../../../components/ui/form/inputPassword/InputPassword";
import {TrustedUserBuilder} from "../../../../classes/business/users/trustedUser/TrustedUserBuilder";

const trustedUserBuilder = new TrustedUserBuilder();

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryCreateLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.Trusted_user_list
    },
    {
        label: habitCategoryCreateLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function TrustedUserCreate() {
    const {t} = useTranslation();

    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<IError | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<IWithoutTrustedUser[]>([]);
    const [selectedUsersError, setSelectedUsersError] = useState<IError | null>(null);
    const [isAuthAdminPanel, setIsAuthAdminPanel] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<IError | null>(null);
    const [rePassword, setRePassword] = useState<string>('');
    const [rePasswordError, setRePasswordError] = useState<IError | null>(null);

    const [users, setUsers] = useState<IWithoutTrustedUser[]>([]);

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        setIsLoadingData(true);
        serviceWithoutTrustedUserList({limit: 'all'}).then(response => {
            setIsLoadingData(false);

            if (response.status) {
                const data = response.data || [];
                const updatedUsers = data?.map(item => {
                    return {...item, name: item.phone + (item.name ? ' | ' + item.name : '')};
                });
                setUsers(updatedUsers);
            }
        }).catch(() => {
            setIsLoadingData(false);
        });
    }, [isInitialLoad]);

    const onHandleClickCreate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void => {
        trustedUserBuilder
            .setUsers(selectedUsers)
            .setPassword(password)
            .setRePassword(rePassword)
            .setCode(code)
            .setIsAuthAdminPanel(isAuthAdminPanel)
            .setIsActive(isActive);

        setSelectedUsersError(trustedUserBuilder.validateUsers());
        setCodeError(trustedUserBuilder.validateCode());
        setPasswordError(trustedUserBuilder.validatePassword());
        setRePasswordError(trustedUserBuilder.validateRePassword());
        setCodeError(trustedUserBuilder.validateCode());

        const trustedUser = trustedUserBuilder.get();
        if (trustedUser.getErrors().length)
            return;

        setIsLoadingCreate(true);
        serviceTrustedUserCreate(trustedUser.getRequestData()).then(response => {
            setIsLoadingCreate(false);
            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Создание',
                    detail: 'Данные не были созданы',
                    life: 3000,
                });

                return;
            }

            setIsCompletion(true);
            setUsers([]);
            setSelectedUsers([]);
            setSelectedUsersError(null);
            setCode('');
            setCodeError(null);
            setPassword('');
            setPasswordError(null);
            setRePassword('');
            setRePasswordError(null);
            setIsActive(true);
            setIsAuthAdminPanel(false);

            setTimeout(() => {
                setIsCompletion(false);

                if (action.action !== actionSplitBtnEnum.CREATE_OR_EDIT && action.url && response.data?.id) {
                    navigate(action.action === actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT ? action.url + `/${response.data.id}` : action.url);
                }

            }, 3000);

            setCode('');
            setIsActive(true);
            setUsers([]);

            toast.current?.show({
                severity: 'success',
                summary: 'Создание',
                detail: 'Данные успешно созданы',
                life: 3000,
            });

            return;
        });
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_trusted_users'), t('label_trusted_users_creating'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isLoadingCreate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isLoadingCreate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingCreate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <MultiSelect
                                            width={'100%'}
                                            value={selectedUsers}
                                            options={users}
                                            onHandleChange={e => setSelectedUsers(e.value)}
                                            title={'Пользователи *'}
                                            placeholder={'Выберите доверенных пользователя/ей'}
                                            panelFooterTemplate={() =>
                                                <MultiSelectFooterPanel
                                                    selectedCount={selectedUsers.length}
                                                    itemsCount={users.length}
                                                />}
                                            filter={true}
                                            display="chip"
                                            appendTo={'self'}
                                            optionLabel={'name'}
                                            dataKey={'id'}
                                            isLoading={isLoadingData}
                                            error={selectedUsersError}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <InputPassword
                                            disabled={!isAuthAdminPanel}
                                            feedback
                                            title={'Введите пароль (для входа в админ панель)'}
                                            placeholder={'Введите пароль'}
                                            width={'100%'}
                                            value={password}
                                            onHandleChange={e => setPassword(e.target.value)}
                                            error={passwordError}
                                        />
                                    </Col>
                                    {
                                        password?.length ?
                                            <Col sm={12}>
                                                <InputPassword
                                                    title={'Повторите пароль еще раз'}
                                                    placeholder={'Введите пароль'}
                                                    width={'100%'}
                                                    value={rePassword}
                                                    onHandleChange={e => setRePassword(e.target.value)}
                                                    error={rePasswordError}
                                                />
                                            </Col> : ''
                                    }
                                    <Col sm={1}>
                                        <InputMask
                                            width={'80px'}
                                            maxlength={4}
                                            mask={'9999'}
                                            value={code}
                                            title={'Код *'}
                                            onHandleChange={e => setCode(e.target.value || '')}
                                            error={codeError}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Активность (Да)"
                                            offLabel="Активность (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isActive}
                                            className="w-9rem"
                                            onChange={e => setIsActive(e.value)}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Дать возможность авторизовываться в админ панели и создать пароль (Да)"
                                            offLabel="Дать возможность авторизовываться в админ панели и создать пароль (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isAuthAdminPanel}
                                            className="w-9rem"
                                            onChange={e => setIsAuthAdminPanel(e.value)}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                urlCreateAndList={routesEnum.List_habit_category_list}
                                                urlCreateAndEdit={routesEnum.List_habit_category_edit_edit}
                                                onHandleBtn={onHandleClickCreate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
            </div>
        </div>
    );
}
