import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {drawerActions} from "../store/reducers/drawer/drawer.slice";
import {appAuthSliceActions} from "../store/reducers/auth/auth.slice";
import {appSliceActions} from "../store/reducers/app/app.slice";

export const useActions = () => {
    const dispatch = useDispatch();

    const appAuthActions = bindActionCreators(appAuthSliceActions, dispatch);
    const drawerAppActions = bindActionCreators(drawerActions, dispatch);
    const appActions = bindActionCreators(appSliceActions, dispatch);

    return {
        appAuthActions,
        drawerAppActions,
        appActions,
    };
}
