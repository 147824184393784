import React, {FC, JSX, ReactElement} from "react";
import '../input.css';

import {IError} from "../../../../types/IError";
import {InputText} from "primereact/inputtext";
import {v4 as uuidv4} from "uuid";
import {Tooltip} from "primereact/tooltip";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {KeyFilterType} from "primereact/keyfilter";

type item = {
    style?: any,
    classMain?: string,
    titleColor?: string,
    value: string,
    id?: string,
    width?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    disabled?: boolean,
    onHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
    maxlength?: number,
    minlength?: number,
    className?: string,
    unmask?: boolean,
    icon?: {
        value: JSX.Element,
        position: 'left' | 'right',
    },
    keyfilter?: KeyFilterType,
    ref?: any,
}

export const Input: FC<item> = (props): ReactElement => {
    const {
        style,
        classMain,
        id,
        value,
        width,
        placeholder,
        title,
        error,
        disabled,
        className,
        maxlength,
        minlength,
        keyfilter,
        onHandleChange,
        onHandleBlur,
        onHandleFocus,
        onHandleClick,
        icon,
        ref,
    } = props;

    const inputId = id || uuidv4();

    const errorStyle = () => {
        if (error?.status === 'error') {
            return {style: 'invalid', icon: 'fas fa-exclamation-circle'};
        } else if (error?.status === 'success') {
            return {style: 'valid', icon: 'fas fa-check'};
        }
    }

    const getStatusError = () => {
        if (error?.status === 'error' || error?.status === 'success') {
            const randString = uuidv4();
            return (
                <div>
                    <FontAwesomeIcon
                        className={`icon i-error custom-tooltip-input-${randString} ${errorStyle()?.style} ${errorStyle()?.icon}`}
                        width={20}
                        height={20}
                        icon={faCircleExclamation}
                    />
                    <Tooltip target={".custom-tooltip-input-" + randString}>
                        <p>{error?.message}</p>
                    </Tooltip>
                </div>
            );
        }
    }

    return (
        <div className={'item-form ' + (error?.status || '') + (classMain ? (' ' + classMain) : '')}>
            {
                title ?
                    <label className={"label " + (errorStyle()?.style)} htmlFor={inputId}>{title}</label>
                    : ''
            }
            <div className="p-inputgroup">
               <span className={"p-input-icon " + (icon?.value ? ('p-input-icon-' + icon.position) : '')}>
                    {
                        icon?.value || ''
                    }
                    <InputText
                        style={style}
                        id={inputId}
                        width={width}
                        className={'input ' + (className || '')}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        maxLength={maxlength}
                        minLength={minlength}
                        onChange={onHandleChange}
                        onBlur={onHandleBlur}
                        onFocus={onHandleFocus}
                        onClick={onHandleClick}
                        keyfilter={keyfilter}
                        ref={ref}
                    />
                   {
                       error?.status === statusFormActionEnum.Error ?
                           <div className="error-icon">
                               {getStatusError()}
                           </div> : ''
                   }
               </span>
            </div>
            {
                error?.status === statusFormActionEnum.Error ?
                    <small style={{color: '#ef9a9a', marginLeft: 5}}>{error.message}</small> : ''
            }
        </div>
    );
}
