import React, {useEffect, useState} from "react";
import './drawer-left.css';
import {motion} from "framer-motion"
import {useAppSelector} from "../../../../hooks/redux";
import {DrawerMenuItem} from "./DrawerMenuItem";
import {IDrawerMenuItem} from "../../../../types/drawerMenu/IDrawerMenu";
import {useActions} from "../../../../hooks/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faRectangleList, faCog, faGears, faUsers} from "@fortawesome/free-solid-svg-icons";
import {routesEnum} from "../../../../enums/routesEnum";
import { v4 as uuidv4 } from 'uuid';
import {useTranslation} from "react-i18next";

export function DrawerLeft() {
    const {user} = useAppSelector(state => state.appAuthSliceReducer);
    const {openedLeft} = useAppSelector(state => state.drawer);
    const {setLeftDrawer} = useActions().drawerAppActions;
    const { t } = useTranslation();

    const [menuItems, setMenuItems] = useState<IDrawerMenuItem[]>([
        {
            id: uuidv4(),
            nameKey: "main",
            name: t('label_main'),
            link: routesEnum.Home,
            icon: <FontAwesomeIcon
                style={{marginRight: '0!important'}}
                className={'icon'}
                width={20}
                height={20}
                icon={faHome}
            />,
            subMenuActive: false,
        },
        {
            id: uuidv4(),
            nameKey: "for_developers",
            name: t('label_for_developers'),
            icon: <FontAwesomeIcon
                className={'icon'}
                width={20}
                height={20}
                icon={faUsers}
            />,
            subMenuActive: false,
            children: [
                {
                    nameKey: "control_habit",
                    name: t('label_control_habit'),
                    link: routesEnum.Developer_habit,
                },
            ]
        },
        {
            id: uuidv4(),
            nameKey: "users",
            name: t('label_users'),
            icon: <FontAwesomeIcon
                className={'icon'}
                width={20}
                height={20}
                icon={faUsers}
            />,
            subMenuActive: false,
            children: [
                {
                    nameKey: "users",
                    name: t('label_users'),
                    link: routesEnum.User_list,
                },
                {
                    nameKey: "trusted_users",
                    name: t('label_trusted_users'),
                    link: routesEnum.Trusted_user_list,
                },
            ]
        },
        {
            id: uuidv4(),
            nameKey: "directories",
            name: t('label_directories'),
            icon: <FontAwesomeIcon
                className={'icon'}
                width={20}
                height={20}
                icon={faRectangleList}
            />,
            subMenuActive: false,
            children: [
                {
                    nameKey: "habit_category",
                    name: t('label_habit_category'),
                    link: routesEnum.List_habit_category_list,
                },
                {
                    nameKey: "habit_category_icon",
                    name: t('label_habit_category_icon'),
                    link: routesEnum.List_habit_icon_category_list,
                },
                {
                    nameKey: "habit_pattern",
                    name: t('label_habit_pattern'),
                    link: routesEnum.List_habit_pattern_list,
                },
            ]
        },
        {
            id: uuidv4(),
            nameKey: "settings",
            name: t('label_settings'),
            icon: <FontAwesomeIcon
                className={'icon'}
                width={20}
                height={20}
                icon={faGears}
            />,
            subMenuActive: false,
            children: [
                {
                    nameKey: "setting_profile",
                    name: t('label_setting_profile'),
                    link: routesEnum.Settings,
                },
                {
                    nameKey: "global_settings",
                    name: t('label_global_setting'),
                    link: routesEnum.GlobalSettingList,
                },
                // {
                //     nameKey: "global_setting_about_us",
                //     name: t('label_global_setting_about_us'),
                //     link: routesEnum.GlobalSettingAboutUs,
                // },
                // {
                //     nameKey: "global_setting_terms_use",
                //     name: t('label_global_setting_terms_use'),
                //     link: routesEnum.GlobalSettingTermsUse,
                // },
            ]
        },
    ]);

    const onHandleSubMenuActive = (id: string, isActive: boolean) => {
        if (!openedLeft) {
            setLeftDrawer(true);
        }

        const updatedMenuItems = menuItems.map((item: IDrawerMenuItem) => {
            item.subMenuActive = item.id === id ? !isActive : false;

            return item;
        });
        setMenuItems(updatedMenuItems);
    }

    useEffect(() => {
        if (!openedLeft) {
            const updatedMenuItems = menuItems.map((item: IDrawerMenuItem) => {
                item.subMenuActive = false;

                return item;
            });
            setMenuItems(updatedMenuItems);
        }
    }, [openedLeft]);

    useEffect(() => {
        const updatedMenuItems = menuItems.map((item: IDrawerMenuItem) => {
            item.name = t('label_' + item.nameKey);
            item?.children?.map(itemSubMenu => {
                itemSubMenu.name =  t('label_' + itemSubMenu.nameKey);
            });

            return item;
        });
        setMenuItems(updatedMenuItems);
    }, [t]);

    useEffect(() => {
        const filteredMenuItems = menuItems.filter((item) => {
            return item.nameKey === 'for_developers' ? user?.user?.roles?.some((role: any) => role.key === 'developer') : true;
        });
        setMenuItems(filteredMenuItems);
    }, []);

    return (
        <motion.div animate={{width: openedLeft ? 246 : 80}} transition={{type: "spring"}} style={{position: 'fixed', top: 57}}>
            <div className={"drawer-left " + (openedLeft ? 'open' : 'close')}>
                <div className="block">
                    <div className="drawer-title">
                        <span>{t('label_navigation')}</span>
                    </div>
                    <div className="drawer-menu">
                        <ul
                            className="list-group">
                            {
                                menuItems.map((item: IDrawerMenuItem) => {
                                    return (
                                        <DrawerMenuItem
                                            key={item.id}
                                            onHandleSubMenuActive={onHandleSubMenuActive}
                                            id={item.id}
                                            link={item.link}
                                            name={item.name}
                                            disable={item.disable}
                                            icon={item.icon}
                                            subMenuActive={item.subMenuActive}
                                            children={item?.children}
                                        />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
