import React, {FC} from "react";
import './breadcrumb.css';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

type item = {
    label: string,
    icon?: JSX.Element,
    route?: string | undefined,
    disable?: boolean | undefined,
}

interface itemsProps {
    items: item[];
}

export const Breadcrumb: FC<itemsProps> = (props): JSX.Element => {
    const {items} = props;
    return (
        <div className={'breadcrumb'}>
            {
                items?.map((item, index) => {
                    return (
                        <div key={index} className={'breadcrumb-item ' + (index === (items.length - 1) ? 'active' : '') + (item.disable ? ' disable' : '')}>
                            {item?.icon}
                            {item?.route ? <Link to={item.route}>{item.label}</Link> : <span>{item.label}</span>}
                            {
                                index !== (items.length - 1)
                                &&
                                <div className="breadcrumb-next">
                                    <FontAwesomeIcon
                                        width={20}
                                        height={20}
                                        icon={faAngleRight}
                                    />
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}
