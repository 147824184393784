import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {IResponse} from "../types/server/IServer";
import {handleAsync} from "./handleAsync";
import user from "../classes/app/user/User";

const URL = 'https://api.humanitool.ru/api/v1';

export type THttpMethod = 'get' | 'post' | 'put' | 'delete';

export default async function makeServiceRequest<T, F>(
    method: THttpMethod,
    uri: string,
    data?: any,
    queryParams?: any,
    isSendToken: boolean = true,
    config?: AxiosRequestConfig,
): Promise<IResponse<T, F>> {
    try {
        let headers: AxiosRequestConfig = config || {};

        if (isSendToken) {
            const userHeaders = user.getHeaderRequest().headers;
            headers = {...headers, ...userHeaders};
        }

        console.log(headers)

        const response: AxiosResponse<IResponse<T, F>> = await axios({
            method,
            url: `${URL}${uri}`,
            data: data,
            params: queryParams,
            headers: headers as Record<string, string>,
        });

        return await handleAsync<T, F>(Promise.resolve(response));
    } catch (error) {
        console.log(error)
        return await handleAsync<T, F>(Promise.reject(error));
    }
}
