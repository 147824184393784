import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line} from 'recharts';
import {serviceNewUsersList} from "../../../services/statistics/StatisticsService";
import {IStatCountNewUsers} from "../../../types/statistics/IStatistics";

export function HomeStatNewUserSection() {
    const {t} = useTranslation();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [items, setItems] = useState<{ name: string, count: number }[]>([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        serviceNewUsersList().then(response => {
            setLoading(false);

            if (!response.success)
                return;

            const updatedItems = response.data?.map(item => {
                return {name: item.name, count: item.count};
            }) || [];

            setItems(updatedItems);
        }).catch(e => {
            setLoading(false);
        })
    }, [isInitialLoad]);

    interface CustomTooltipProps {
        active?: boolean;
        payload?: any[];
        label?: string;
    }

    const CustomTooltip: React.FC<CustomTooltipProps> = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{background: '#25344c', padding: '10px 15px', borderRadius: 15}}>
                    <p>{label}</p>
                    <p style={{fontSize: 14, color: '#9a9dcf'}}>Новые пользователи : <span>{payload[0].value}</span></p>
                </div>
            );
        }

        return null;
    };

    return (
        <div style={{position: 'relative', width: '100%', height: '300px'}}>
            <div className="title" style={{color: '#7b869a', marginBottom: 15}}>
                Новые пользователи (по месяцам)
            </div>
            <ResponsiveContainer style={{display: !loading ? 'block' : 'none'}} width="100%" height="100%">
                <AreaChart
                    data={items}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis tickMargin={5} dataKey="name"/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8"/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
