import React, {FC, JSX, ReactElement, useEffect, useState} from "react";
import './dropdown.css';

import {IError} from "../../../../types/IError";
import {Dropdown as PrimeDropdown, DropdownChangeEvent} from 'primereact/dropdown';
import {faBoxOpen, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {v4 as uuidv4} from "uuid";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Input} from "../input/Input";

type item = {
    value: any,
    options: any,
    showClear?: boolean,
    loading?: boolean,
    id?: string,
    width?: string | number,
    optionLabel?: string,
    dataKey?: string,
    scrollHeight?: number,
    placeholder?: string,
    title?: string,
    emptyMessage?: () => ReactElement,
    optionTemplate?: (option: any) => ReactElement,
    error?: IError | null,
    disabled?: boolean,
    panelClassName?: string,
    onHandleChange?: (e: DropdownChangeEvent) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
    onHandleFilter?: (value: string) => void,
    filterTemplate?: () => JSX.Element,
    isLocalFilter?: boolean,
    className?: string,
    panelFooterTemplate?: () => JSX.Element,
    isFilter?: boolean,
    placeholderLoading?: string,
    newButtonContent?: {
        label: string,
        linkUrl: string,
    },
    iconInput?: {
      value: JSX.Element,
      position: 'left' | 'right',
    },
}

export const Dropdown: FC<item> = (props): ReactElement => {
    const {
        id,
        width,
        options,
        scrollHeight,
        value,
        loading,
        showClear,
        optionTemplate,
        optionLabel,
        dataKey,
        placeholder,
        title,
        error,
        panelClassName,
        disabled,
        className,
        emptyMessage,
        onHandleChange,
        panelFooterTemplate,
        isFilter,
        isLocalFilter,
        filterTemplate,
        placeholderLoading,
        newButtonContent,
        onHandleFilter,
        iconInput,
    } = props;


    const [filterValue, setFilterValue] = useState<string>('');

    const inputId = id || uuidv4();
    const optionLabelValue = optionLabel || 'name';
    const emptyMessageEl = emptyMessage || (() => {
        return (
            <div className={'dropdown-empty-message-content'}>
                {
                    loading ?
                        <div className="dropdown-loading">
                            <span className="loader" />
                            Загрузка данных
                        </div>
                        :
                        <div className="icon">
                            <FontAwesomeIcon
                                size="5x"
                                color={'#86a7e1'}
                                icon={faBoxOpen}
                            />
                            <span>
                                Нет данных
                            </span>
                        </div>
                }
            </div>
        );
    });

    const [filterTimeout, setFilterTimeout] = useState<any>(null);
    const onHandleFilterDefault = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!onHandleFilter) {
            setFilterValue(e.target.value);

            return;
        }

        setFilterValue(e.target.value);

        // Очистка предыдущего таймера задержки, если есть
        if (filterTimeout) {
            clearTimeout(filterTimeout);
        }

        // Установка нового таймера задержки
        const newFilterTimeout = setTimeout(() => {
            // Логика фильтрации с использованием query
            console.log('Filtered with:', e.target.value);

            onHandleFilter(e.target.value);
        }, 500);

        setFilterTimeout(newFilterTimeout);
    }

    let filterTemplateDefault = undefined;
    if (filterTemplate === undefined) {
        filterTemplateDefault = () => {
            return <div>
                <div className="form-search">
                    <Input
                        onHandleChange={onHandleFilterDefault}
                        value={filterValue}
                        placeholder={'поиск'}
                        icon={iconInput}
                    />
                </div>
            </div>;
        }
    }

    return (
        <div className={"item-form"}>
            <label className="label" htmlFor={inputId}>{title}</label>
            <div className={'dropdown-content'}>
                {
                    loading ?
                        <div className="loading-mask">
                            <div className="loading-icon">
                                <FontAwesomeIcon
                                    width={20}
                                    height={20}
                                    icon={faCircleNotch}
                                    spin={true}
                                />
                            </div>
                        </div> : ''
                }
                <PrimeDropdown
                    filterTemplate={isLocalFilter ? undefined : filterTemplate || filterTemplateDefault}
                    style={width ? {width} : undefined}
                    appendTo={'self'}
                    disabled={disabled}
                    className={'dropdown ' + (className || '') + (loading ? ' loading' : '')}
                    panelClassName={'dropdown-panel ' + (panelClassName || '')}
                    scrollHeight={scrollHeight ? scrollHeight + 'px' : '350px'}
                    emptyMessage={emptyMessageEl}
                    width={width || '100%'}
                    id={inputId}
                    value={value}
                    itemTemplate={optionTemplate}
                    dataKey={dataKey || 'id'}
                    options={options}
                    onChange={onHandleChange}
                    optionLabel={optionLabelValue}
                    placeholder={loading ? (placeholderLoading || 'Загрузка данных, ждите...')  : placeholder}
                    showClear
                    panelFooterTemplate={newButtonContent ?
                        () => {
                            return (
                                <Link
                                    className={'dropdown-new-element'}
                                    to={newButtonContent.linkUrl}>
                                    <Button label={newButtonContent.label} />
                                </Link>
                            )
                        }
                        : panelFooterTemplate}
                    filter={isLocalFilter || isFilter}
                />
            </div>
        </div>
    );
}
