import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {drawerReducer} from "./reducers/drawer/drawer.slice";
import {appAuthSliceReducer} from "./reducers/auth/auth.slice";
import {appSliceReducer} from "./reducers/app/app.slice";

export const store = configureStore({
   reducer: {
      drawer: drawerReducer,
      appAuthSliceReducer,
      appSliceReducer,
   },
   middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
