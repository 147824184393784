import React, {useEffect, useRef, useState} from "react";
import '../../../../styles/list.css';

import {Col, Container, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome, faLanguage} from "@fortawesome/free-solid-svg-icons";
import {routesEnum} from "../../../../../enums/routesEnum";

import {IError} from "../../../../../types/IError";
import {useNavigate} from "react-router-dom";
import {Toast} from "primereact/toast";
import {actionBtn, FormSplitButton} from "../../../../../components/ui/buttons/FormSplitButton";
import {actionSplitBtnEnum} from "../../../../../enums/actionSplitBtnEnum";
import {InputNumber} from "../../../../../components/ui/form/inputNumber/InputNumber";
import {useTranslation} from "react-i18next";
import {Input} from "../../../../../components/ui/form/input/Input";
import {LottieLoadingSuccessfully} from "../../../../../components/ui/animations/LottieLoadingSuccessfully";
import {Breadcrumb} from "../../../../../components/ui/breadcrumb/Breadcrumb";
import {serviceHabitCategoryList} from "../../../../../services/lists/habit/habitCategoryService";
import {ITranslation} from "../../../../../types/lists/ITranslation";
import {Button} from "primereact/button";
import {ILanguage} from "../../../../../types/lang/ILanguage";
import {TranslationName} from "../../../../../components/business/translation/TranslationName";
import {Panel} from "primereact/panel";
import {IHabitCategory} from "../../../../../types/lists/habit/IHabitCategory";
import {Dropdown} from "../../../../../components/ui/form/dropdown/Dropdown";
import {UploadImage} from "../../../../../components/ui/upload/UploadImage";
import {ToggleButton} from "primereact/togglebutton";
import {serviceHabitIconCategoryCreate} from "../../../../../services/lists/habit/habitIconCategoryService";
import {
    initializeLanguagesAndTranslations,
    syncAllTranslations,
    updateTranslation,
    updateTranslationOnBlur,
} from "../../../../../utils/translationsUtils";
import {validateNumber} from "../../../../../helpers/validateFormatHelper";
import {CategoryIconBuilder} from "../../../../../classes/business/habits/iconCategory/CategoryIconBuilder";
import {ConvectorImage} from "../../../../../helpers/ConvectorImage";

const categoryBuilder = new CategoryIconBuilder();

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryCreateLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.List_habit_icon_category_list
    },
    {
        label: habitCategoryCreateLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function HabitIconCategoryCreate() {
    const {t} = useTranslation();

    const [value, setValue] = useState<string>('');
    const [valueError, setValueError] = useState<IError | null>(null);
    const [icon, setIcon] = useState<File|null>(null);
    const [iconPdf, setIconPdf] = useState<File|null>(null);
    const [iconPng, setIconPng] = useState<File|null>(null);
    const [iconError, setIconError] = useState<IError|null>(null);
    const [selectedCategory, setSelectedCategory] = useState<IHabitCategory | null>(null);
    const [selectedCategoryError, setSelectedCategoryError] = useState<IError | null>(null);
    const [sort, setSort] = useState<number>(500);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isNegative, setIsNegative] = useState<boolean>(false);
    const [translations, setTranslations] = useState<ITranslation[]>([]);
    const [, setTranslationError] = useState<IError | null>(null);

    const [categories, setCategories] = useState<IHabitCategory[]>([]);

    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [nameCodeLang, setNameCodeLang] = useState<string>('');

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        initializeLanguagesAndTranslations(setLanguages, setTranslations);
    }, []);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        setIsLoadingData(true);
        serviceHabitCategoryList({limit: 'all'}).then(response => {
            setIsLoadingData(false);

            if (response.status) {
                setCategories(response.data || []);
            }
        }).catch(() => {
            setIsLoadingData(false);
        });
    }, [isInitialLoad]);

    const onHandleClickCreate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void => {
        categoryBuilder
            .setName(value)
            .setIcon(icon)
            .setIconPdf(iconPdf)
            .setIconPng(iconPng)
            .setCategory(selectedCategory)
            .setSort(sort)
            .setTranslation(translations)
            .setIsActive(isActive)
            .setIsNegative(isNegative);

        setValueError(categoryBuilder.validateName());
        setSelectedCategoryError(categoryBuilder.validateCategory());
        setIconError(categoryBuilder.validateIcon());
        setTranslationError(categoryBuilder.validateTranslation());

        const category = categoryBuilder.get();

        setTranslations(category.translations);
        if (category.getErrors().length)
            return;

        setIsLoadingCreate(true);
        serviceHabitIconCategoryCreate(category.getRequestData()).then(response => {
            setIsLoadingCreate(false);
            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Создание',
                    detail: 'Данные не были созданы',
                    life: 3000,
                });

                return;
            }

            setIsCompletion(true);

            setTimeout(() => {
                setIsCompletion(false);

                if (action.action !== actionSplitBtnEnum.CREATE_OR_EDIT && action.url && response.data?.id) {
                    navigate(action.action === actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT ? action.url + `/${response.data.id}` : action.url);
                }

            }, 3000);

            setIcon(null);
            setIconError(null);
            setValueError(null);
            setValue('');
            setSort(500);
            setSelectedCategory(null);
            setSelectedCategoryError(null);
            setTranslations([]);

            const updatedTranslations = translations.map(item => {
                return {...item, name: '', error: null, isDisable: false, isProcessing: false};
            });
            setTranslations(updatedTranslations);

            toast.current?.show({
                severity: 'success',
                summary: 'Создание',
                detail: 'Данные успешно созданы',
                life: 3000,
            });

            return;
        });
    }

    const onHandleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        updateTranslationOnBlur(e.target.value, setValue, setNameCodeLang, setValueError, translations, setTranslations);
    }

    const onHandleTranslation = (e: React.ChangeEvent<HTMLInputElement>, code: string) => {
        updateTranslation(e.target.value, code, translations, setTranslations);
    }

    const onHandleSyncTranslation = async () => {
        await syncAllTranslations(value, null, nameCodeLang, null, translations, setTranslations);
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_habit_category_icon'), t('label_habit_category_icon_creating'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isLoadingCreate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isLoadingCreate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingCreate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div style={{
                                            display: 'grid',
                                            justifyContent: 'center',
                                            marginTop: 35,
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            justifyItems: 'center',
                                        }}>
                                            <UploadImage
                                                onImageUpload={async (file) => {
                                                    setIcon(file);
                                                    if (file) {
                                                        const resIconPdf = await ConvectorImage.convectorSvgToPdf(file);
                                                        const resIconPng = await ConvectorImage.convectorSvgToPng(file);
                                                        setIconPdf(resIconPdf);
                                                        setIconPng(resIconPng);
                                                    }
                                                }}
                                                value={icon}
                                                title={iconError ? 'Выберите иконку' : 'Иконка svg *'}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Dropdown
                                            isLocalFilter={true}
                                            width={'100%'}
                                            value={selectedCategory}
                                            options={categories}
                                            onHandleChange={e => setSelectedCategory(e.value)}
                                            title={'Категория привычки *'}
                                            placeholder={'Выберите категорию привычки'}
                                            error={selectedCategoryError}
                                        />
                                    </Col>
                                    <Col sm={10}>
                                        <Input
                                            maxlength={255}
                                            value={value}
                                            title={'Наименование *'}
                                            onHandleChange={e => setValue(e.target.value)}
                                            onHandleBlur={onHandleNameBlur}
                                            error={valueError}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <InputNumber
                                            title={'Сортировка'}
                                            value={sort}
                                            onHandleChange={(e) => setSort(e.value || 0)}
                                            onHandleBlur={e => setSort(validateNumber(e.target.value))}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Активность (Да)"
                                            offLabel="Активность (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isActive}
                                            className="w-9rem"
                                            onChange={e => setIsActive(e.value)}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Негативная привычка (Да)"
                                            offLabel="Негативная привычка (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isNegative}
                                            className="w-9rem"
                                            onChange={e => setIsNegative(e.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Panel header="Переводы" toggleable style={{marginTop: 25}}>
                                            <Button
                                                onClick={onHandleSyncTranslation}
                                                disabled={!value?.length}
                                                style={{marginTop: 15, marginBottom: 30}}
                                                label={'Перевести автоматически'}
                                                icon={<FontAwesomeIcon
                                                    width={30}
                                                    height={30}
                                                    size={'2x'}
                                                    icon={faLanguage}
                                                    style={{marginRight: 10}}
                                                />}
                                            />
                                            {
                                                translations.map(translation => {
                                                    const lang = languages.find(item => item.code === translation.code);

                                                    return lang ?
                                                        <TranslationName
                                                            key={lang.code}
                                                            nameLang={lang.name}
                                                            image={lang.image}
                                                            code={lang.code}
                                                            value={translation.name}
                                                            isDisable={translation.isDisable}
                                                            isProcessing={translation.isProcessing}
                                                            onHandleBlur={onHandleTranslation}
                                                            error={translation.error}
                                                        /> : ''
                                                })
                                            }
                                        </Panel>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                urlCreateAndList={routesEnum.List_habit_icon_category_list}
                                                urlCreateAndEdit={routesEnum.List_habit_icon_category_edit_edit}
                                                onHandleBtn={onHandleClickCreate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
            </div>
        </div>
    );
}
