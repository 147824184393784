import {IDefaultParams, IResponse} from "../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";
import {ITrustedRequest, ITrustedUser, IWithoutTrustedUser} from "../../types/users/ITrustedUser";


export async function serviceDeveloperClearHabits(userId: string): Promise<IResponse<ITrustedUser, null>> {
    const uri = '/admin/habits/clear-my-habits';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<ITrustedUser, null>(method, uri, {userId});
    } catch (error) {
        throw error;
    }
}
