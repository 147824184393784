import {getCookie} from "typescript-cookie";

class User {

    private _token: any = null;

    getActualData() {
        let data: any = getCookie('auth_user');
        if (data) {
            data = JSON.parse(data);
        }

        if (data) {
            this.token = data?.token;
        }
    }

    generateBoundary() {
        let boundary = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._';

        for (let i = 0; i < 16; i++) {
            boundary += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return boundary;
    }

    getHeaderRequest() {
        this.getActualData();

        return {headers: {'Authorization': 'Bearer ' + this.token}};
    }

    getHeaderRequestFormData() {
        this.getActualData();
        const boundary = this.generateBoundary();
        return {
            headers: {
                'Authorization': 'Bearer ' + this.token,
            }
        };
    }

    set token(value) {
        this._token = value;
    }

    get token() {
        return this._token;
    }
}

const user = new User();

export default user;
