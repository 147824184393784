import {IDefaultParams, IResponse} from "../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";
import {ITrustedRequest, ITrustedUser, IWithoutTrustedUser} from "../../types/users/ITrustedUser";

export async function serviceTrustedUserList(params?: IDefaultParams): Promise<IResponse<ITrustedUser[], null>> {
    const uri = '/admin/users/trusted-users';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<ITrustedUser[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceWithoutTrustedUserList(params?: IDefaultParams): Promise<IResponse<IWithoutTrustedUser[], null>> {
    const uri = '/admin/users/without-trusted';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IWithoutTrustedUser[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceTrustedUserById(id: string): Promise<IResponse<ITrustedUser, null>> {
    const uri = '/admin/users/trusted-users/' + id;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<ITrustedUser, null>(method, uri);
    } catch (error) {
        throw error;
    }
}

export async function serviceTrustedUserCreate(data: ITrustedRequest): Promise<IResponse<ITrustedUser, null>> {
    const uri = '/admin/users/trusted-users';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<ITrustedUser, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceTrustedUserUpdate(id: string, data: ITrustedRequest): Promise<IResponse<null, null>> {
    const uri = '/admin/users/trusted-users/' + id;
    const method: THttpMethod = 'put';

    try {
        return await makeServiceRequest<null, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceTrustedUserDelete(id: string): Promise<IResponse<null, null>> {
    const uri = '/admin/users/trusted-users/' + id;
    const method: THttpMethod = 'delete';

    try {
        return await makeServiceRequest<null, null>(method, uri);
    } catch (error) {
        throw error;
    }
}
