import React, {useEffect, useRef, useState} from "react";
import '../../../styles/list.css';

import {useNavigate, useParams} from 'react-router-dom';
import {Col, Container, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome} from "@fortawesome/free-solid-svg-icons";

import {IError} from "../../../../types/IError";
import {Toast} from "primereact/toast";
import Lottie from "lottie-react";
import animation from "../../../../animation/lottie/animation.json";
import {routesEnum} from "../../../../enums/routesEnum";
import {actionBtn, FormSplitButton} from "../../../../components/ui/buttons/FormSplitButton";
import {LottieLoadingSuccessfully} from "../../../../components/ui/animations/LottieLoadingSuccessfully";
import {useTranslation} from "react-i18next";
import {actionSplitBtnEnum} from "../../../../enums/actionSplitBtnEnum";
import {Breadcrumb} from "../../../../components/ui/breadcrumb/Breadcrumb";
import {IWithoutTrustedUser} from "../../../../types/users/ITrustedUser";
import {InputMask} from "../../../../components/ui/form/inputMask/InputMask";
import {ToggleButton} from "primereact/togglebutton";
import {serviceTrustedUserById, serviceTrustedUserUpdate} from "../../../../services/users/trustedUserService";
import {TrustedUserBuilder} from "../../../../classes/business/users/trustedUser/TrustedUserBuilder";
import {InputPassword} from "../../../../components/ui/form/inputPassword/InputPassword";

const trustedUserBuilder = new TrustedUserBuilder();

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryEditLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.Trusted_user_list
    },
    {
        label: habitCategoryEditLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function TrustedUserEdit() {
    const {t} = useTranslation();

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const paramId = useParams().id;

    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<IError | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<IWithoutTrustedUser[]>([]);
    const [selectedUsersError, setSelectedUsersError] = useState<IError | null>(null);
    const [isAuthAdminPanel, setIsAuthAdminPanel] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<IError | null>(null);
    const [rePassword, setRePassword] = useState<string>('');
    const [rePasswordError, setRePasswordError] = useState<IError | null>(null);

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        if (!paramId)
            return;

        setIsLoadingData(true);

        serviceTrustedUserById(paramId).then(response => {
            setIsLoadingData(false);

            if (response?.success) {
                const data = response.data;

                setCode(data?.code || '');
                setIsActive(data?.isActive || false);
                if (data?.userId?.length)
                    setSelectedUsers([{...data, id: data.userId}]);

            } else console.log('Ошибка');
        }).catch(error => {
            setIsLoadingData(false);
            console.error('Ошибка загрузки данных:', error);
        });

    }, [paramId, isInitialLoad]);

    const onHandleClickUpdate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void | null => {
        trustedUserBuilder
            .setUsers(selectedUsers)
            .setPassword(password)
            .setRePassword(rePassword)
            .setCode(code)
            .setIsAuthAdminPanel(isAuthAdminPanel)
            .setIsActive(isActive);

        setSelectedUsersError(trustedUserBuilder.validateUsers());
        setCodeError(trustedUserBuilder.validateCode());
        setPasswordError(trustedUserBuilder.validatePassword());
        setRePasswordError(trustedUserBuilder.validateRePassword());
        setCodeError(trustedUserBuilder.validateCode());

        const trustedUser = trustedUserBuilder.get();
        if (trustedUser.getErrors().length)
            return;

        if (!paramId)
            return;

        setIsUpdate(true);
        serviceTrustedUserUpdate(paramId, trustedUser.getRequestData()).then(response => {
            setIsUpdate(false);

            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Обновление',
                    detail: 'Данные не были обновлены',
                    life: 3000,
                });

                return null;
            }

            setCodeError(null);
            setSelectedUsersError(null);
            setPasswordError(null);
            setRePasswordError(null);

            setIsCompletion(true);

            setTimeout(() => {
                setIsCompletion(false);

                if (action.action !== actionSplitBtnEnum.CREATE_OR_EDIT && action.url) {
                    navigate(action.action === actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT ? action.url + `/${paramId}` : action.url);
                }

            }, 3000);

            toast.current?.show({
                severity: 'success',
                summary: 'Обновление',
                detail: 'Данные успешно обновлены',
                life: 3000,
            });

            return null;
        });
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_trusted_users'), t('label_trusted_users_creating'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isUpdate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isUpdate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingData && !isUpdate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <InputPassword
                                            disabled={!isAuthAdminPanel}
                                            feedback
                                            title={'Введите пароль (для входа в админ панель)'}
                                            placeholder={'Введите пароль'}
                                            width={'100%'}
                                            value={password}
                                            onHandleChange={e => setPassword(e.target.value)}
                                            error={passwordError}
                                        />
                                    </Col>
                                    {
                                        password?.length ?
                                            <Col sm={12}>
                                                <InputPassword
                                                    title={'Повторите пароль еще раз'}
                                                    placeholder={'Введите пароль'}
                                                    width={'100%'}
                                                    value={rePassword}
                                                    onHandleChange={e => setRePassword(e.target.value)}
                                                    error={rePasswordError}
                                                />
                                            </Col> : ''
                                    }
                                    <Col sm={1}>
                                        <InputMask
                                            width={'80px'}
                                            maxlength={4}
                                            mask={'9999'}
                                            value={code}
                                            title={'Код *'}
                                            onHandleChange={e => setCode(e.target.value || '')}
                                            error={codeError}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Активность (Да)"
                                            offLabel="Активность (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isActive}
                                            className="w-9rem"
                                            onChange={e => setIsActive(e.value)}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Дать возможность авторизовываться в админ панели и создать пароль (Да)"
                                            offLabel="Дать возможность авторизовываться в админ панели и создать пароль (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isAuthAdminPanel}
                                            className="w-9rem"
                                            onChange={e => setIsAuthAdminPanel(e.value)}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                isEdit={true}
                                                urlCreateAndList={routesEnum.List_habit_category_list}
                                                urlCreateAndEdit={routesEnum.List_habit_category_edit_edit}
                                                onHandleBtn={onHandleClickUpdate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
                {
                    isLoadingData && !isUpdate ?
                        <Lottie
                            style={{width: 350, height: 350, textAlign: 'center', margin: 'auto', paddingTop: 35}}
                            width={50}
                            height={50}
                            animationData={animation}/> : ''
                }
            </div>
        </div>
    );
}
