import React, {FC, ReactElement} from "react";
import '../input.css';

import {IError} from "../../../../types/IError";
import {
    InputNumber as PrimeInputNumber,
    InputNumberChangeEvent,
} from 'primereact/inputnumber';
import {v4 as uuidv4} from "uuid";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "primereact/tooltip";

type item = {
    value: number|null,
    id?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    disabled?: boolean,
    useGrouping?: boolean,
    prefix?: string,
    suffix?: string,
    minFractionDigits?: number,
    onHandleChange?: (e: InputNumberChangeEvent) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    maxlength?: number,
    minlength?: number,
    className?: string,
    unmask?: boolean
}

export const InputNumber: FC<item> = (props): ReactElement => {
    const {
        id,
        value,
        placeholder,
        title,
        error,
        disabled,
        className,
        useGrouping,
        prefix,
        suffix,
        maxlength,
        minFractionDigits,
        onHandleChange,
        onHandleBlur,
    } = props;

    const inputId = id || uuidv4();

    const errorStyle = () => {
        if (error?.status === 'error') {
            return {style: 'invalid', icon: 'fas fa-exclamation-circle'};
        }
        else if (error?.status === 'success') {
            return {style: 'valid', icon: 'fas fa-check'};
        }
    }

    const getStatusError = () => {
        if (error?.status === 'error' || error?.status === 'success') {
            const randString = uuidv4();
            return (
                <div>
                    <FontAwesomeIcon
                        className={`icon i-error custom-tooltip-input-${randString} ${errorStyle()?.style} ${errorStyle()?.icon}`}
                        width={20}
                        height={20}
                        icon={faCircleExclamation}
                    />
                    <Tooltip target={".custom-tooltip-input-" + randString}>
                        <p>{error?.message}</p>
                    </Tooltip>
                </div>
            );
        }
    }

    return (
        <div className={'item-form ' + (error?.status || '')}>
            {
                title ?
                    <label className="label" htmlFor={inputId}>{title}</label>
                    : ''
            }
            <div className="p-inputgroup">
                <PrimeInputNumber
                    id={inputId}
                    className={'input ' + (className || '')}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxlength}
                    onChange={onHandleChange}
                    onBlur={onHandleBlur}
                    useGrouping={useGrouping}
                    prefix={prefix}
                    suffix={suffix}
                    minFractionDigits={minFractionDigits}
                />
                {
                    error?.status === statusFormActionEnum.Error ?
                        <div className="error-icon">
                            {getStatusError()}
                        </div> : ''
                }
            </div>
            {
                error?.status === statusFormActionEnum.Error ?
                    <small style={{color: '#ef9a9a', marginLeft: 5}}>{error.message}</small> : ''
            }
        </div>
    );
}
