import {setCookie} from "typescript-cookie";
import {IResponse} from "../types/server/IServer";
import {AxiosResponse} from "axios";

export async function handleAsync<T, F>(
    promise: Promise<AxiosResponse<IResponse<T, F>>>
): Promise<IResponse<T, F>> {
    try {
        const response = await promise;
        const responseData = response.data;

        if (response.status === 204) {
            return { status: response.status, data: null, success: true };
        }

        if (response.status === 401)
            handleStatus401();

        responseData.status = response.status;

        return responseData;
    } catch (error) {
        return {
            data: null,
            success: false,
            errors: {
                status: null,
                errors: { message: (error as Error).message },
                message: error instanceof Error ? (error as Error).message : String(error),
            },
            status: 500,
        };
    }
}

function handleStatus401() {
    setCookie('auth_user', null);
}
