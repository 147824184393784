import React, {FC, ReactElement} from "react";
import './form/input.css';

import {IError} from "../../types/IError";

type item = {
    error?: IError | null,
}

export const EditorRenderHeader: FC<item> = (props): ReactElement => {
    return (
        <div id="toolbar">
            <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
        </span>
            <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
        </span>
            <span className="ql-formats">
            <button className="ql-align" value=""></button>
            <button className="ql-align" value="center"></button>
            <button className="ql-align" value="right"></button>
            <button className="ql-align" value="justify"></button>
        </span>
            <span className="ql-formats">
            <button className="ql-link"></button>
        </span>
            <span className="ql-formats">
            <button className="ql-header" value="1"></button>
            <button className="ql-header" value="2"></button>
            <button className="ql-header" value="3"></button>
        </span>
            <span className="ql-formats">
            <select className="ql-color"></select>
            <select className="ql-background"></select>
        </span>
            <span className="ql-formats">
            <button className="ql-script" value="super"></button>
            <button className="ql-script" value="sub"></button>
        </span>
        </div>
    );
}
