import {IDefaultParams, IResponse} from "../../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../../makeServiceRequest";
import {IHabitCategoryIconRequest, IHabitIconCategory} from "../../../types/lists/habit/IHabitIconCategory";
import {AxiosRequestConfig} from "axios";
import {type} from "node:os";

export async function serviceHabitIconCategoryList(params: IDefaultParams): Promise<IResponse<IHabitIconCategory[], null>> {
    const uri = '/admin/lists/habit-category-icons';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IHabitIconCategory[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitIconCategoryById(id: string): Promise<IResponse<IHabitIconCategory, null>> {
    const uri = '/admin/lists/habit-category-icons/' + id;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IHabitIconCategory, null>(method, uri);
    } catch (error) {
        throw error;
    }
}

function prepareData(data: IHabitCategoryIconRequest): FormData {
    const formData = new FormData();
    formData.append('name', data.name);
    if (typeof data.icon !== 'string') {
        formData.append('icon', data.icon instanceof File ? data.icon : new Blob([data.icon]));
    }
    else {
        formData.append('icon', data.icon);
    }
    formData.append('categoryId', data.categoryId);
    if (data.isActive !== undefined) formData.append('isActive', String(data.isActive));
    if (data.isNegative !== undefined) formData.append('isNegative', String(data.isNegative));
    formData.append('translations', JSON.stringify(data.translations));
    if (data.sort !== undefined) formData.append('sort', String(data.sort));
    if (data.iconPdf)
        formData.append('iconPdf', data.iconPdf instanceof File ? data.iconPdf : new Blob([data.iconPdf], {type: 'application/pdf'}), data.iconPdf.name);
    if (data.iconPng)
        formData.append('iconPng', data.iconPng instanceof File ? data.iconPng : new Blob([data.iconPng], {type: 'image/png'}), data.iconPng.name);

    return formData;
}

export async function serviceHabitIconCategoryCreate(data: IHabitCategoryIconRequest): Promise<IResponse<IHabitIconCategory, null>> {
    const uri = '/admin/lists/habit-category-icons';
    const method: THttpMethod = 'post';

    const config: AxiosRequestConfig = {
        headers: {
            // 'Content-Type': 'multipart/form-data'
        }
    };

    const formData = new FormData();
    formData.append('name', data.name);
    console.log('Adding icon:', data.icon);
    if (typeof data.icon === 'string') {
        formData.append('icon', new Blob([data.icon], {type: 'text/plain'}), 'icon.txt');
    } else {
        formData.append('icon', data.icon);
    }
    formData.append('categoryId', data.categoryId);
    if (data.isActive !== undefined) formData.append('isActive', String(data.isActive));
    if (data.isNegative !== undefined) formData.append('isNegative', String(data.isNegative));
    formData.append('translations', JSON.stringify(data.translations));
    if (data.sort !== undefined) formData.append('sort', String(data.sort));
    if (data.iconPdf) {
        console.log('Adding iconPdf:', data.iconPdf);
        formData.append('iconPdf', data.iconPdf);
    }
    if (data.iconPng) {
        console.log('Adding iconPng:', data.iconPng);
        formData.append('iconPng', data.iconPng);
    }

    console.log('FormData entries:');

    try {
        return await makeServiceRequest<IHabitIconCategory, null>(method, uri, formData, null, true, config);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitIconCategoryUpdate(id: string, data: IHabitCategoryIconRequest): Promise<IResponse<null, null>> {
    const uri = '/admin/lists/habit-category-icons/' + id + '/update';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<null, null>(method, uri, prepareData(data));
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitIconCategoryDelete(id: string): Promise<IResponse<null, null>> {
    const uri = '/admin/lists/habit-category-icons/' + id;
    const method: THttpMethod = 'delete';

    try {
        return await makeServiceRequest<null, null>(method, uri);
    } catch (error) {
        throw error;
    }
}
