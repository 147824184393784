import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationRu from '../../locales/ru/translations.json';
import formRu from '../../locales/ru/form.json';
import translationEn from '../../locales/en/translations.json';
import formEn from '../../locales/en/form.json';
import {languageEnum} from "../../enums/languageEnum";
import {getLocalLang} from "../../helpers/languageHelper";

i18n.use(initReactI18next).init({
    fallbackLng: getLocalLang().code,
    lng: getLocalLang().code,
    resources: {
        ru: {
            translations: translationRu,
            form: formRu,
        },
        en: {
            translations: translationEn,
            form: formEn,
        }
    },
    ns: ['translations', 'form'],
    defaultNS: 'translations'
});

i18n.languages = [languageEnum.Ru, languageEnum.En];

export default i18n;
