import React, {FC, JSX, ReactElement, useRef} from "react";
import '../input.css';
import './select-multi.css';

import {IError} from "../../../../types/IError";
import {v4 as uuidv4} from "uuid";
import {
    MultiSelect as PrimeMultiSelect,
    MultiSelectChangeEvent,
    MultiSelectFilterEvent
} from 'primereact/multiselect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {ElementStatusForm} from "../../ElementStatusForm";

type item = {
    value: any,
    id?: string,
    options: any,
    width?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    display?: 'chip' | 'comma',
    optionLabel?: string,
    disabled?: boolean,
    filter?: boolean,
    onHandleChange?: (e: MultiSelectChangeEvent) => void,
    onFilter?: (e: MultiSelectFilterEvent) => void,
    className?: string,
    itemTemplate?: (option: any) => JSX.Element,
    panelFooterTemplate?: () => JSX.Element,
    appendTo?: 'self' | HTMLElement | undefined | null,
    max?: number | string,
    min?: number | string,
    dataKey?: string,
    maxSelectedLabels?: number,
    isLoading?: boolean,
}

export const MultiSelect: FC<item> = (props): ReactElement => {
    const {
        id,
        value,
        width,
        placeholder,
        options,
        optionLabel,
        title,
        error,
        disabled,
        display,
        filter,
        className,
        appendTo,
        onHandleChange,
        onFilter,
        itemTemplate,
        panelFooterTemplate,
        max,
        min,
        dataKey,
        maxSelectedLabels,
        isLoading,
    } = props;

    const ref = useRef<PrimeMultiSelect>(null);

    const inputId = id || uuidv4();

    const errorStyle = () => {
        if (error?.status === 'error') {
            return {style: 'invalid', icon: 'fas fa-exclamation-circle'};
        }
        else if (error?.status === 'success') {
            return {style: 'valid', icon: 'fas fa-check'};
        }
    }

    return (
        <div className={'item-form ' + (error?.status || '')}>
            {
                title ?
                    <label className={"label " + (errorStyle()?.style)} htmlFor={inputId}>{title}</label>
                    : ''
            }
            <div className="p-inputgroup">
                {
                    isLoading ?
                        <div className="loading-mask">
                            <div className="loading-icon">
                                <FontAwesomeIcon
                                    width={20}
                                    height={20}
                                    icon={faCircleNotch}
                                    spin={true}
                                />
                            </div>
                        </div> : ''
                }
                <PrimeMultiSelect
                    ref={ref}
                    filterPlaceholder={'Поиск'}
                    onFilter={onFilter}
                    appendTo={appendTo}
                    id={inputId}
                    value={value}
                    width={width}
                    onChange={onHandleChange}
                    options={options}
                    disabled={disabled}
                    optionLabel={optionLabel}
                    itemTemplate={itemTemplate}
                    panelFooterTemplate={panelFooterTemplate}
                    filter={filter}
                    placeholder={placeholder}
                    display={display}
                    className={'input ' + (className || '')}
                    dataKey={dataKey}
                    max={max}
                    min={min}
                    maxSelectedLabels={maxSelectedLabels}
                />
                {
                    error?.status === statusFormActionEnum.Error || error?.status === statusFormActionEnum.Success ?
                        <ElementStatusForm error={error} /> : ''
                }
            </div>
            {
                error?.status === statusFormActionEnum.Error ?
                    <small style={{color: '#ef9a9a', marginLeft: 5}}>{error.message}</small> : ''
            }
        </div>
    );
}
