import {Canvg} from 'canvg';
import {jsPDF} from 'jspdf';

export class ConvectorImage {
    public static svgToPdf(file: File | null, isResFile: boolean = true): Promise<Blob | null> {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject(new Error("Файл не указан"));
                return;
            }

            const reader = new FileReader();

            reader.onload = function(event) {
                const svgContent = event?.target?.result;
                if (!svgContent) {
                    reject(new Error("Содержимое SVG не найдено"));
                    return;
                }

                const svgBlob = new Blob([svgContent as BlobPart], { type: "image/svg+xml;charset=utf-8" });
                const url = URL.createObjectURL(svgBlob);

                const doc = new jsPDF();

                const img = new Image();
                img.onload = function() {
                    doc.addImage(img, 'JPEG', 0, 0, img.width / 4, img.height / 4);

                    // Генерация Blob из PDF
                    const pdfDataUri = doc.output('datauristring');
                    const pdfBlob = ConvectorImage.dataURItoBlob(pdfDataUri);
                    resolve(pdfBlob);
                };
                img.onerror = function() {
                    reject(new Error('Ошибка при загрузке SVG'));
                };
                img.src = url;
            };

            reader.readAsText(file);
        });
    }

    private static dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    public static async convectorSvgToPng(svgFile: File): Promise<File> {
        const svgContent = await ConvectorImage.readFileAsText(svgFile);

        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                return reject('Failed to get canvas context');
            }

            const v = Canvg.fromString(ctx, svgContent);
            v.render();

            canvas.toBlob((blob) => {
                if (!blob) {
                    return reject('Failed to convert canvas to blob');
                }

                const pngFile = new File([blob], `${svgFile.name.split('.')[0]}.png`, { type: 'image/png' });
                resolve(pngFile);
            });
        });
    }

    private static readFileAsText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(reader.error);
            reader.readAsText(file);
        });
    }

    public static async convectorSvgToPdf(svgFile: File): Promise<File> {
        const svgContent = await ConvectorImage.readFileAsText(svgFile);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('Failed to get canvas context');
        }

        const v = Canvg.fromString(ctx, svgContent);
        await v.render();

        const pdf = new jsPDF();
        const imgData = canvas.toDataURL('image/png');

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        const pdfBlob = pdf.output('blob');
        return new File([pdfBlob], `${svgFile.name.split('.')[0]}.pdf`, { type: 'application/pdf' });
    }

}
