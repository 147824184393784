import React, {useEffect, useState} from "react";

import {Route, Routes, useNavigate} from "react-router-dom";
import {Login} from "./auth/sign/Login";

import {Dialog} from 'primereact/dialog';
import {Button} from "primereact/button";
import {Layout} from "../components/ui/layout/Layout";

import {Home} from "./home/Home";

import {HabitCategoryList} from "./lists/habit/category/list/HabitCategoryList";
import {HabitCategoryCreate} from "./lists/habit/category/create/HabitCategoryCreate";
import {HabitCategoryEdit} from "./lists/habit/category/edit/HabitCategoryEdit";

import {HabitIconCategoryList} from "./lists/habit/iconCategory/list/HabitIconCategoryList";
import {HabitIconCategoryCreate} from "./lists/habit/iconCategory/create/HabitIconCategoryCreate";
import {HabitIconCategoryEdit} from "./lists/habit/iconCategory/edit/HabitIconCategoryEdit";

import {HabitPatternList} from "./lists/habit/pattern/list/HabitPatternList";
import {HabitPatternCreate} from "./lists/habit/pattern/create/HabitPatternCreate";
import {HabitPatternEdit} from "./lists/habit/pattern/edit/HabitPatternEdit";

import {TrustedUserList} from "./users/trustedUser/list/TrustedUserList";
import {TrustedUserCreate} from "./users/trustedUser/create/TrustedUserCreate";
import {TrustedUserEdit} from "./users/trustedUser/edit/TrustedUserEdit";

import {UserList} from "./users/user/list/UserList";

import {DevHabit} from "./developer/habit/DevHabit";

import {useAppSelector} from "../hooks/redux";
import {useActions} from "../hooks/actions";
import {routesEnum} from "../enums/routesEnum";
import {GlobalAppList} from "./setting/globalApp/list/GlobalAppList";
import {GlobalAppSettingCreate} from "./setting/globalApp/create/GlobalAppSettingCreate";
import {GlobalAppSettingEdit} from "./setting/globalApp/edit/GlobalAppSettingEdit";

export function Index() {
    const navigate = useNavigate();
    const {checkAuthUser} = useActions().appAuthActions;
    const {isAuth} = useAppSelector(state => state.appAuthSliceReducer);
    const {selectedModeStore} = useAppSelector(state => state.appSliceReducer);

    const [isModalAuth, setIsModalAuth] = useState<boolean>(false);

    useEffect(() => {
        checkAuthUser();
        setIsModalAuth(!isAuth && window.location.pathname !== '/login');
    }, [isAuth]);

    return (
        <div className={selectedModeStore}>
            {
                window.location.pathname !== '/login' ?
                    <div className={'layout'}>
                        {
                            isModalAuth ?
                                <Dialog header="Вы не авторизованы!" closable={false} position={'top-right'} modal
                                        visible={isModalAuth} style={{width: '50vw'}} onHide={() => {
                                }}
                                        draggable={false} resizable={false}>
                                    <p className="m-0">Пожалуйста, авторизуйтесь в приложении, для дальнейшей
                                        работы!</p>
                                    <br/>
                                    <Button onClick={() => navigate('/login')} label="Перейти к авторизации"
                                            aria-label="Перейти к авторизации"/>
                                </Dialog> : ''
                        }

                        <Layout>
                            <Routes>
                                <Route path={routesEnum.Home} element={<Home/>}/>

                                <Route path={routesEnum.List_habit_category_list} element={<HabitCategoryList/>}/>
                                <Route path={routesEnum.List_habit_category_create} element={<HabitCategoryCreate/>}/>
                                <Route path={routesEnum.List_habit_category_edit_with_id}
                                       element={<HabitCategoryEdit/>}/>

                                <Route path={routesEnum.List_habit_icon_category_list}
                                       element={<HabitIconCategoryList/>}/>
                                <Route path={routesEnum.List_habit_icon_category_create}
                                       element={<HabitIconCategoryCreate/>}/>
                                <Route path={routesEnum.List_habit_icon_category_edit_with_id}
                                       element={<HabitIconCategoryEdit/>}/>

                                <Route path={routesEnum.List_habit_pattern_list} element={<HabitPatternList/>}/>
                                <Route path={routesEnum.List_habit_pattern_create} element={<HabitPatternCreate/>}/>
                                <Route path={routesEnum.List_habit_pattern_edit_with_id} element={<HabitPatternEdit/>}/>

                                <Route path={routesEnum.Trusted_user_list} element={<TrustedUserList/>}/>
                                <Route path={routesEnum.Trusted_user_create} element={<TrustedUserCreate/>}/>
                                <Route path={routesEnum.Trusted_user_edit_with_id} element={<TrustedUserEdit/>}/>

                                <Route path={routesEnum.User_list} element={<UserList/>}/>

                                <Route path={routesEnum.GlobalSettingList} element={<GlobalAppList/>}/>
                                <Route path={routesEnum.GlobalSettingCreate} element={<GlobalAppSettingCreate/>}/>
                                <Route path={routesEnum.GlobalSettingEditWithId} element={<GlobalAppSettingEdit/>}/>

                                <Route path={routesEnum.Developer_habit} element={<DevHabit/>}/>
                            </Routes>
                        </Layout>
                    </div>
                    :
                    <Routes>
                        <Route path={routesEnum.Login} element={<Login/>}/>
                    </Routes>
            }
        </div>
    );
}
