import React, {FC, useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import {Chart} from "primereact/chart";

interface IProps {
    value: number;
    maxValue: number,
    percent: number,
}

export const HomeStatDeviceSection: FC<IProps> = (props): JSX.Element => {
    const {value, maxValue, percent} = props;

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const data = {
            datasets: [
                {
                    data: [value, maxValue - value],
                    backgroundColor: ['#8884d8', '#1f2d40'],
                    borderWidth: 0,
                }
            ],
        };

        const options = {
            cutout: '80%',
            plugins: {
                tooltip: {
                    enabled: false, // Отключаем всплывающие подсказки
                },
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [maxValue, value]);

    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '34px',
                    color: '#96a9c9',
                    fontWeight: 'bold',
                    marginTop: '3px',
                }}
                className={'percent'}>{percent}<span
                style={{fontSize: '20px'}}>%</span>
            </div>
            <Chart
                style={{width: 140, height: 140, display: 'inline-flex'}}
                type="doughnut"
                data={chartData}
                options={chartOptions}
                className="w-full md:w-30rem"/>
        </div>
    );
}
