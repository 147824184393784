import React, {FC, ReactElement} from "react";

type item = {
    selectedCount: number,
    itemsCount: number,
}

export const MultiSelectFooterPanel: FC<item> = (props): ReactElement => {
    const {
        selectedCount, itemsCount
    } = props;

    return (
        <div className="py-2 px-3">
            {
                selectedCount === itemsCount ?
                    <span>
                            <b>{selectedCount === 0 && itemsCount === 0 ? '' : 'Выбраны все элементы'}</b>
                        </span>
                    :
                    <span>
                            <b>{selectedCount}</b> элементов{selectedCount > 1 ? 's' : ''} выбрано / из {itemsCount}
                        </span>
            }
        </div>
    );
}
