import React from "react";
import {ITranslation} from "../types/lists/ITranslation";
import {translate} from "@vitalets/google-translate-api";
import {IError} from "../types/IError";
import {getLanguages} from "../helpers/languageHelper";
import {ILanguage} from "../types/lang/ILanguage";
import {languageEnum} from "../enums/languageEnum";

// Функция для получения данных о переводе
const getTranslatedSentences = async (text: string, code?: string): Promise<{ langCode: string | null, translatedText: string | null }> => {
    try {
        const response: any = await translate(text, {to: code || languageEnum.En});
        const raw = response.raw;
        const sentences: any[] = raw.sentences || [];
        if (sentences.length > 0) {
            return {langCode: raw.src, translatedText: sentences[0].trans};
        } else {
            return {langCode: null, translatedText: null};
        }
    } catch (error) {
        console.error('Ошибка при переводе:', error);
        return {langCode: null, translatedText: null};
    }
};

// Функция определяет язык на основе, что ввел пользователь
export const updateTranslationOnBlur = async (
    value: string | null,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    setNameCodeLang: React.Dispatch<React.SetStateAction<string>>,
    setValueError: React.Dispatch<React.SetStateAction<IError | null>>,
    translations: ITranslation[],
    setTranslations: React.Dispatch<React.SetStateAction<ITranslation[]>>,
    isDesc?: boolean,
    setDescCodeLang?: React.Dispatch<React.SetStateAction<string>> | null,
) => {
    const valueData = value?.trim() || '';
    setValue(valueData);

    if (!valueData?.length)
        return;
    else setValueError(null);

    try {
        const {langCode} = await getTranslatedSentences(valueData);
        if (langCode?.length) {
            if (!isDesc)
                setNameCodeLang(langCode);
            else {
                setDescCodeLang!(langCode);
            }

            const updatedTranslations = await Promise.all(
                translations.map(item => {
                    return item.code === langCode ?
                        {...item, name: !isDesc ? valueData : item.name, description: isDesc ? valueData : item.description}
                        : item;
                })
            );

            setTranslations(updatedTranslations);
        }
    } catch (error) {
        console.error('Ошибка при обновлении перевода при потере фокуса:', error);
    }
};

// Функция для записи данных, что приходят нам от пользователя, что решил сам заполнить
export const updateTranslation = (
    value: string,
    code: string,
    translations: ITranslation[],
    setTranslations: React.Dispatch<React.SetStateAction<ITranslation[]>>,
    isDesc?: boolean,
) => {
    const updatedTranslations = translations.map(item =>
        item.code === code
            ? {
                ...item,
                name: !isDesc ? value : item.name,
                description: isDesc ? value : item.description,
                isDisable: false,
                isProcessing: false,
                error: value.length ? null : item.error
            }
            : {...item, isDisable: false, error: value.length ? null : item.error}
    );

    setTranslations(updatedTranslations);
};

// Функция для обновления перевода для отдельного элемента
const updateTranslationItem = async (
    value: string,
    item: ITranslation,
    code: string,
    isEdit?: boolean,
): Promise<ITranslation> => {
    try {
        const {translatedText} = await getTranslatedSentences(value, code);

        return {
            ...item,
            name: !isEdit ? (translatedText || item.name) : item.name,
            description: isEdit ? (translatedText || item.description) : item.description,
            isDisable: false,
            isProcessing: false
        };
    } catch (error) {
        console.error('Ошибка при обновлении перевода элемента:', error);
        return item;
    }
};

// Функция выполняет все переводы в translations
export const syncAllTranslations = async (
    valueName: string,
    valueDesc: string | null,
    nameCodeLang: string,
    descCodeLang: string | null,
    translations: ITranslation[],
    setTranslations: React.Dispatch<React.SetStateAction<ITranslation[]>>,
    isDesc?: boolean,
) => {
    try {
        const updatedTranslations = await Promise.all(
            translations.map(item => {
                if (item.code !== nameCodeLang && valueName?.length)
                    return updateTranslationItem(valueName, item, item.code);

                // console.log((item.code !== descCodeLang) && (descCodeLang?.length && valueDesc?.length && isDesc), item.code, descCodeLang, descCodeLang?.length, valueDesc?.length, isDesc)
                if (item.code !== descCodeLang && descCodeLang?.length && valueDesc?.length && isDesc) {
                    return updateTranslationItem(valueDesc, item, item.code, isDesc);
                }

                return item;
            })
        );

        setTranslations(updatedTranslations);
    } catch (error) {
        console.error('Ошибка при синхронизации переводов:', error);
    }
};

export const initializeLanguagesAndTranslations = (
    setLanguages: React.Dispatch<React.SetStateAction<ILanguage[]>>,
    setTranslations: React.Dispatch<React.SetStateAction<ITranslation[]>>
) => {
    const langs = getLanguages(); // Предположим, что у вас уже есть функция getLanguages(), возвращающая массив языков
    setLanguages(langs);
    setTranslations(langs.map(item => ({
        name: '',
        isDisable: false,
        isProcessing: false,
        code: item.code
    })));
};





