import React, {FC, ReactElement} from "react";
import './form/input.css';

import {IError} from "../../types/IError";
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "primereact/tooltip";
import {statusFormActionEnum} from "../../enums/statusFormActionEnum";
import {Image} from "primereact/image";

type item = {
    error?: IError | null,
}

export const ElementStatusForm: FC<item> = (props): ReactElement => {
    const {
        error,
    } = props;

    const errorStyle = () => {
        if (error?.status === statusFormActionEnum.Error) {
            return {style: 'invalid', icon: 'fas fa-exclamation-circle'};
        }
        else if (error?.status === statusFormActionEnum.Success) {
            return {style: 'valid', icon: 'fas fa-check'};
        }
    }

    const randString = uuidv4();

    return (
        <div className={'error-icon'}>
            <FontAwesomeIcon
                className={`icon i-error custom-tooltip-input-${randString} ${errorStyle()?.style} ${errorStyle()?.icon}`}
                width={20}
                height={20}
                icon={faCircleExclamation}
            />
            <Tooltip target={".custom-tooltip-input-" + randString}>
                <p>{error?.message}</p>
            </Tooltip>
        </div>
    );
}
