import React, {FC, useEffect, useRef} from "react";

import Lottie from "lottie-react";
import lottiePlayer from '../../../animation/lottie/lottieLoadingSuccessfully2.json';

interface itemsProps {
    isCompletion: boolean;
    isUpdate: boolean;
}

interface itemsProps {
    isUpdate: boolean;
    isCompletion: boolean;
}

export const LottieLoadingSuccessfully: FC<itemsProps> = (props): JSX.Element => {
    const { isUpdate, isCompletion } = props;
    const lottieRef = useRef<any>();

    useEffect(() => {
        const animationInstance = lottieRef.current;

        if (isUpdate) {
            animationInstance.playSegments([0, 90], true);
        } else if (isCompletion) {
            animationInstance.playSegments([91, 350], true);
        }
    }, [isUpdate, isCompletion]);

    return (
        <div>
            <Lottie
                lottieRef={lottieRef}
                style={{ width: 250, height: 250, textAlign: 'center', margin: 'auto' }}
                width={50}
                height={50}
                animationData={lottiePlayer}
            />
        </div>
    );
}
