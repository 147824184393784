import {ITranslation} from "../../../../types/lists/ITranslation";
import {Category} from "./Category";
import {AbstractBuilder} from "../../AbstractBuilder";
import {IError} from "../../../../types/IError";
import {validateNumber} from "../../../../helpers/validateFormatHelper";
import {ErrorHelper} from "../../../../helpers/ErrorHelper";

export class CategoryBuilder extends AbstractBuilder {
    private category!: Category;

    constructor() {
        super();
        this.create();
    }

    protected create(): void {
        this.category = new Category();
    }

    public setName(value: string | null): this {
        this.category.name = value;

        return this;
    }

    public validateName(): IError {
        return !this.category.name?.length
            ? ErrorHelper.getMessageError('Наименование', 'Обязательно для заполнения', 'category.name', true)
            : ErrorHelper.getMessageError('Наименование', 'Данные введены верно', 'category.name');
    }

    public setColor(value: string | null): this {
        this.category.color = value;

        return this;
    }

    public validateColor(): IError {
        return !this.category.color?.length
            ? ErrorHelper.getMessageError('Цвет', 'Вы не выбрали цвет', 'category.color', true)
            : ErrorHelper.getMessageError('Цвет', 'Данные выбраны верно', 'category.color');
    }

    public setTranslation(value: ITranslation[]): this {
        this.category.translations = value;

        return this;
    }

    public validateTranslation(): IError {
        this.category.translations.map(item => {
            item.error = !item.name?.length
                ? ErrorHelper.getMessageError('Перевод', 'Вы не выбрали цвет', 'category.translation.name', true)
                : ErrorHelper.getMessageError('Перевод', 'Данные выбраны верно', 'category.translation.name');
        });

        return !this.category.translations?.length
            ? ErrorHelper.getMessageError('Переводы', 'Обязательно для заполнения', 'category.translation', true)
            : ErrorHelper.getMessageError('Переводы', 'Данные выбраны верно', 'category.translation');
    }

    public setIsActive(value: boolean): this {
        this.category.isActive = value;

        return this;
    }

    public setSort(value: string | number): this {
        this.category.sort = validateNumber(value);

        return this;
    }

    private validate(): void {
        this.category.errors.push(this.validateName());
        this.category.errors.push(this.validateColor());
        this.category.errors.push(this.validateTranslation());
    }

    public get(): Category {
        const category = this.category;
        this.validate();
        this.create();

        return category;
    }
}
