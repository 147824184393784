import {ITranslation} from "../../../../types/lists/ITranslation";
import {Pattern} from "./Pattern";
import {AbstractBuilder} from "../../AbstractBuilder";
import {IError} from "../../../../types/IError";
import {validateNumber} from "../../../../helpers/validateFormatHelper";
import {ErrorHelper} from "../../../../helpers/ErrorHelper";
import {IHabitIconCategory} from "../../../../types/lists/habit/IHabitIconCategory";

export class PatternBuilder extends AbstractBuilder {
    private pattern!: Pattern;

    constructor() {
        super();
        this.create();
    }

    protected create(): void {
        this.pattern = new Pattern();
    }

    public setName(value: string | null): this {
        this.pattern.name = value;

        return this;
    }

    public validateName(): IError {
        return !this.pattern.name?.length
            ? ErrorHelper.getMessageError('Наименование', 'Обязательно для заполнения', 'category.name', true)
            : ErrorHelper.getMessageError('Наименование', 'Данные введены верно', 'category.name');
    }

    public setDescription(value: string|null) : this {
        this.pattern.description = value;

        return this;
    }

    public validateDescription() : IError {
        return !this.pattern.name?.length
            ? ErrorHelper.getMessageError('Подробное описание', 'Обязательно для заполнения', 'category.description', true)
            : ErrorHelper.getMessageError('Подробное описание', 'Данные введены верно', 'category.description');
    }

    public setCategoryIcon(value: IHabitIconCategory | null) : this {
        this.pattern.categoryIcon = value;

        return this;
    }

    public validateCategoryIcon() : IError {
        return !this.pattern.categoryIcon?.id
            ? ErrorHelper.getMessageError('Категория иконки', 'Вы не выбрали категорию', 'category.categoryIcon', true)
            : ErrorHelper.getMessageError('Категория иконки', 'Данные выбраны верно', 'category.categoryIcon');
    }

    public setTranslation(value: ITranslation[]): this {
        this.pattern.translations = value;

        return this;
    }

    public validateTranslation(): IError {
        this.pattern.translations.map(item => {
            item.error = !item.name?.length ?
                ErrorHelper.getMessageError('Перевод', 'Поле обязательно для заполнение', 'category.translation', true)
                :
                ErrorHelper.getMessageError('Перевод', 'Данные заполнены верно', 'category.translation')

            item.errorDesc = !item.description?.length
                ? ErrorHelper.getMessageError('Перевод', 'Поле обязательно для заполнение', 'category.translation', true)
                : ErrorHelper.getMessageError('Перевод', 'Данные заполнены верно', 'category.translation')

        });

        return !this.pattern.translations?.length
            ? ErrorHelper.getMessageError('Переводы', 'Не все поля были заполнены', 'category.translation', true)
            : ErrorHelper.getMessageError('Переводы', 'Данные заполнены верно', 'category.translation');
    }

    public setIsActive(value: boolean): this {
        this.pattern.isActive = value;

        return this;
    }

    public setIsNegative(value: boolean): this {
        this.pattern.isNegative = value;

        return this;
    }

    public setSort(value: string | number): this {
        this.pattern.sort = validateNumber(value);

        return this;
    }

    private validate(): void {
        this.pattern.errors.push(this.validateName());
        this.pattern.errors.push(this.validateDescription());
        this.pattern.errors.push(this.validateCategoryIcon());
        this.pattern.errors.push(this.validateTranslation());
    }

    public get(): Pattern {
        const pattern = this.pattern;
        this.validate();
        this.create();

        return pattern;
    }
}
