import {ITranslation} from "../../../../types/lists/ITranslation";
import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {IHabitCategoryIconRequest} from "../../../../types/lists/habit/IHabitIconCategory";
import {IHabitCategory} from "../../../../types/lists/habit/IHabitCategory";

export class CategoryIcon {
    public name!: string | null;
    public icon!: File | string | null;
    public category!: IHabitCategory | null;
    public isActive: boolean = true;
    public isNegative: boolean = false;
    public sort: number = 500;
    public translations!: ITranslation[];
    public errors: IError[] = [];
    public iconPdf!: File | null;
    public iconPng!: File | null;

    public getErrors(isError: boolean = true): IError[] {
        return this.errors.filter(item => item.status === (isError ? statusFormActionEnum.Error : statusFormActionEnum.Success));
    }

    public getRequestData(): IHabitCategoryIconRequest {
        if (this.getErrors().length)
            throw new Error("Ошибка: некорректные данные");

        return {
            name: this.name as string,
            icon: this.icon as (File | string),
            iconPdf: this.iconPdf,
            iconPng: this.iconPng,
            categoryId: this.category?.id as string,
            isActive: this.isActive,
            isNegative: this.isNegative,
            translations: this.translations,
            sort: this.sort,
        };
    }
}
