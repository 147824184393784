import React, {FC, JSX} from "react";
import './control.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Skeleton} from "primereact/skeleton";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";

interface itemsProps {
    isLoading: boolean,
    isBtnLoading: boolean,
    onHandleClickDelete: (id: string) => void,
    itemId: string,
    helperItemId: string | null,
    isBtnEditHidden?: boolean,
    isBtnEditDisabled?: boolean,
    btnEditUrl?: string,

    isBtnDeleteHidden?: boolean,
    isBtnDeleteDisabled?: boolean,
}

export const Control: FC<itemsProps> = (props): JSX.Element => {
    const {
        isLoading,
        isBtnLoading,
        itemId,
        helperItemId,
        isBtnEditHidden,
        isBtnEditDisabled,
        isBtnDeleteHidden,
        btnEditUrl,
        isBtnDeleteDisabled,
        onHandleClickDelete
    } = props;

    const btnEdit = (): JSX.Element => {
        let btn: JSX.Element | null;
        if (isBtnEditDisabled) {
            btn = <Button
                disabled={isBtnEditDisabled}
                className={'btn-edit'}
                icon={
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faPenToSquare}
                    />
                }
            />
        }
        else if (btnEditUrl) {
            btn = <Link
                to={btnEditUrl}>
                <Button
                    disabled={isBtnEditDisabled}
                    className={'btn-edit'}
                    icon={
                        <FontAwesomeIcon
                            width={20}
                            height={20}
                            icon={faPenToSquare}
                        />
                    }
                />
            </Link>
        } else {
            btn = <Button
                disabled={isBtnEditDisabled}
                className={'btn-edit'}
                icon={
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faPenToSquare}
                    />
                }
            />
        }

        return btn;
    }

    return (
        isLoading ?
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Skeleton className="mr-3" width="35px" height="35px"/>
                <Skeleton width="35px" height="35px"/>
            </div>
            :
            <div className={'control'}>
                {
                    !isBtnEditHidden ?
                        btnEdit() : ''
                }
                {
                    !isBtnDeleteHidden ?
                        <Button
                            disabled={(helperItemId === itemId ? isBtnLoading : false) || isBtnDeleteDisabled}
                            loading={helperItemId === itemId ? isBtnLoading : false}
                            onClick={() => onHandleClickDelete(itemId)}
                            className={'btn-delete'}
                            icon={
                                <FontAwesomeIcon
                                    width={20}
                                    height={20}
                                    icon={faTrashCan}
                                />
                            }
                        /> : ''
                }
            </div>
    );
}
