import React, {FC, useState} from "react";
import '../style.css';

import {Col, Row} from 'react-grid-system';
import {useTranslation} from "react-i18next";
import {InputNumber} from "../../../../components/ui/form/inputNumber/InputNumber";

interface IProps {
    value: number,
    handleValueBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
}

export const SectionNumber: FC<IProps> = (props): JSX.Element => {
    const {t} = useTranslation();

    const {value, handleValueBlur} = props;

    const [valueField, setValueField] = useState(value);

    return (
        <div className="content-type-html">
            <Row>
                <Col sm={12}>
                    <div className="splitter-content">
                        <InputNumber
                            title={'Основные данные'}
                            minFractionDigits={2}
                            value={valueField}
                            onHandleChange={e => setValueField(e.value || 0)}
                            onHandleBlur={handleValueBlur}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
