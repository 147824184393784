import {FC, PropsWithChildren} from "react"
import {Header} from "../header/Header";
import {DrawerLeft} from "../menu/drawers/DrawerLeft";
import {motion} from "framer-motion";
import {useAppSelector} from "../../../hooks/redux";

export const Layout: FC<PropsWithChildren<{}>> = ({children}) => {
    const {openedLeft} = useAppSelector(state => state.drawer);
    return (
        <>
            <Header/>
            <main className={'main'}>
                <motion.div
                    className={"content " + (openedLeft ? 'content-open' : '')}
                    animate={{left: openedLeft ? 246 : 80}}
                    transition={{type: "spring"}}>
                    {children}
                </motion.div>
            </main>
            <DrawerLeft />
        </>
    );
}
