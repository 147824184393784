import React, {useEffect, useRef, useState} from "react";

import {Container, Row, Col} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome} from "@fortawesome/free-solid-svg-icons";
import {routesEnum} from "../../../enums/routesEnum";

import {IError} from "../../../types/IError";
import {useNavigate} from "react-router-dom";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {LottieLoadingSuccessfully} from "../../../components/ui/animations/LottieLoadingSuccessfully";
import {Breadcrumb} from "../../../components/ui/breadcrumb/Breadcrumb";
import {serviceDeveloperClearHabits} from "../../../services/developer/developerService";
import {serviceUserList} from "../../../services/users/userService";
import {IUser} from "../../../types/users/IUser";
import {Dropdown} from "../../../components/ui/form/dropdown/Dropdown";
import {Button} from "primereact/button";

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryCreateLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.List_habit_category_list
    },
    {
        label: habitCategoryCreateLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function DevHabit() {
    const {t} = useTranslation();

    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [selectedUserError, setSelectedUserError] = useState<string | null>(null);
    const [users, setUsers] = useState<IUser[]>([]);

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        setIsLoadingData(true);
        serviceUserList({limit: 'all'}).then(response => {
            setIsLoadingData(false);

            if (response.status) {
                const data = response.data || [];
                const updatedUsers = data?.map(item => {
                    return {...item, name: item.name || item.phone};
                });
                setUsers(updatedUsers);
            }
        }).catch(() => {
            setIsLoadingData(false);
        });
    }, [isInitialLoad]);

    const onHandleClick = (e: React.MouseEvent<HTMLElement>): void => {
        if (!selectedUser?.id) {
            setSelectedUserError("Вы не выбрали пользователя");
            return;
        }

        setSelectedUserError(null);

        setIsLoadingCreate(true);
        serviceDeveloperClearHabits(selectedUser.id).then(response => {
            setIsLoadingCreate(false);
            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Создание',
                    detail: 'Данные не были удалены',
                    life: 3000,
                });

                return;
            }

            setIsCompletion(true);
            setUsers([]);

            setTimeout(() => {
                setIsCompletion(false);
            }, 3000);

            setUsers([]);

            toast.current?.show({
                severity: 'success',
                summary: 'Создание',
                detail: 'Данные успешно созданы',
                life: 3000,
            });

            return;
        });
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_habit_category'), t('label_habit_category_creating'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isLoadingCreate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isLoadingCreate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingCreate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Dropdown
                                            isLocalFilter={true}
                                            width={'100%'}
                                            value={selectedUser}
                                            options={users}
                                            onHandleChange={e => setSelectedUser(e.value)}
                                            title={'Пользователи *'}
                                            placeholder={'Выберите пользователя'}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <Button label={"Удалить привычки"} onClick={onHandleClick}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
            </div>
        </div>
    );
}
