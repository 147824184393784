import React, {useEffect, useRef, useState} from "react";
import '../../../../styles/list.css';

import {useNavigate, useParams} from 'react-router-dom';
import {Container, Row, Col} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome, faLanguage} from "@fortawesome/free-solid-svg-icons";

import {IError} from "../../../../../types/IError";
import {Toast} from "primereact/toast";
import Lottie from "lottie-react";
import animation from "../../../../../animation/lottie/animation.json";
import {ColorPickerHSBType, ColorPickerRGBType} from "primereact/colorpicker";
import {routesEnum} from "../../../../../enums/routesEnum";
import {
    serviceHabitCategoryById,
    serviceHabitCategoryUpdate
} from "../../../../../services/lists/habit/habitCategoryService";
import {actionBtn, FormSplitButton} from "../../../../../components/ui/buttons/FormSplitButton";
import {statusFormActionEnum} from "../../../../../enums/statusFormActionEnum";
import {ColorPicker} from "../../../../../components/ui/form/colorPicker/ColorPicker";
import {InputNumber} from "../../../../../components/ui/form/inputNumber/InputNumber";
import {Input} from "../../../../../components/ui/form/input/Input";
import {LottieLoadingSuccessfully} from "../../../../../components/ui/animations/LottieLoadingSuccessfully";
import {useTranslation} from "react-i18next";
import {IListTranslation, ITranslation} from "../../../../../types/lists/ITranslation";
import {actionSplitBtnEnum} from "../../../../../enums/actionSplitBtnEnum";
import {Breadcrumb} from "../../../../../components/ui/breadcrumb/Breadcrumb";
import {ILanguage} from "../../../../../types/lang/ILanguage";
import {getLanguages} from "../../../../../helpers/languageHelper";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {TranslationName} from "../../../../../components/business/translation/TranslationName";
import {translate} from "@vitalets/google-translate-api";
import {CategoryBuilder} from "../../../../../classes/business/habits/category/CategoryBuilder";
import {
    initializeLanguagesAndTranslations, syncAllTranslations, updateTranslation, updateTranslationOnBlur,
} from "../../../../../utils/translationsUtils";
import {validateNumber} from "../../../../../helpers/validateFormatHelper";

const categoryBuilder = new CategoryBuilder();

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryEditLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.List_habit_category_list
    },
    {
        label: habitCategoryEditLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function HabitCategoryEdit() {
    const {t} = useTranslation();

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const paramId = useParams().id;

    const [value, setValue] = useState<string>('');
    const [valueError, setValueError] = useState<IError | null>(null);
    const [color, setColor] = useState<string | ColorPickerRGBType | ColorPickerHSBType>('');
    const [colorError, setColorError] = useState<IError | null>(null);
    const [sort, setSort] = useState<number>(500);
    const [translations, setTranslations] = useState<ITranslation[]>([]);
    const [translationError, setTranslationError] = useState<IError | null>(null);

    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [nameCodeLang, setNameCodeLang] = useState<string>('');

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        initializeLanguagesAndTranslations(setLanguages, setTranslations);
    }, []);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        if (!paramId)
            return;

        setIsLoadingData(true);

        serviceHabitCategoryById(paramId).then(response => {
            setIsLoadingData(false);

            if (response?.success) {
                const data = response.data;

                setValue(data?.name || '');
                setSort(data?.sort || 500);
                setColor((data?.color || '').substring(1));
                const updatedTranslations = translations.map(item => {
                    const translationData = response?.data?.translations?.[item.code];

                    if (translationData && translationData.name)
                        return {...item, name: translationData.name};
                    else
                        return item;
                });

                setTranslations(updatedTranslations);

            } else console.log('Ошибка');
        }).catch(error => {
            setIsLoadingData(false);
            console.error('Ошибка загрузки данных:', error);
        });
    }, [paramId, isInitialLoad]);

    const onHandleClickUpdate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void | null => {
        categoryBuilder
            .setName(value)
            .setColor(color as string)
            .setSort(sort)
            .setTranslation(translations);

        setValueError(categoryBuilder.validateName());
        setColorError(categoryBuilder.validateColor());
        setTranslationError(categoryBuilder.validateTranslation());

        const category = categoryBuilder.get();
        setTranslations(category.translations);

        if (category.getErrors().length || !paramId)
            return;

        setIsUpdate(true);

        serviceHabitCategoryUpdate(paramId, category.getRequestData()).then(response => {
            setIsUpdate(false);

            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Обновление',
                    detail: 'Данные не были обновлены',
                    life: 3000,
                });

                return null;
            }

            setIsCompletion(true);

            setTimeout(() => {
                setIsCompletion(false);

                if (action.action !== actionSplitBtnEnum.CREATE_OR_EDIT && action.url) {
                    navigate(action.action === actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT ? action.url + `/${paramId}` : action.url);
                }

            }, 3000);

            setValueError(null);
            setColorError(null);

            const updatedTranslations = translations.map(item => {
                return {...item, name: '', error: null, isDisable: false, isProcessing: false};
            });
            setTranslations(updatedTranslations);

            toast.current?.show({
                severity: 'success',
                summary: 'Обновление',
                detail: 'Данные успешно обновлены',
                life: 3000,
            });

            return null;
        });
    }

    const onHandleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        updateTranslationOnBlur(e.target.value, setValue, setNameCodeLang, setValueError, translations, setTranslations);
    }

    const onHandleSyncTranslation = async () => {
        await syncAllTranslations(value, null, nameCodeLang, null, translations, setTranslations);
    }

    const onHandleTranslation = (e: React.ChangeEvent<HTMLInputElement>, code: string) => {
        updateTranslation(e.target.value, code, translations, setTranslations);
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_habit_category'), t('label_habit_category_editing'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isUpdate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isUpdate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingData && !isUpdate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={10}>
                                        <Input
                                            maxlength={255}
                                            value={value}
                                            title={'Наименование *'}
                                            onHandleChange={e => setValue(e.target.value)}
                                            onHandleBlur={onHandleNameBlur}
                                            error={valueError}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <InputNumber
                                            title={'Сортировка'}
                                            value={sort}
                                            onHandleChange={(e) => setSort(e.value || 0)}
                                            onHandleBlur={e => setSort(validateNumber(e.target.value))}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <div className="color-select-content">
                                            <div style={{
                                                display: 'table',
                                                padding: '5px 5px 8px 0',
                                                fontSize: '14px',
                                                color: '#fff9'
                                            }}>
                                                {
                                                    colorError?.status === statusFormActionEnum.Error ?
                                                        <span
                                                            style={{color: '#ef9a9a'}}>{colorError.message + ' *'}</span>
                                                        :
                                                        <span>Выберите цвет категории</span>
                                                }
                                            </div>
                                            <div>
                                                <ColorPicker
                                                    format={'hex'}
                                                    value={color}
                                                    onChange={e => setColor(e.value || '')}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Panel header="Переводы" toggleable style={{marginTop: 25}}>
                                            <Button
                                                onClick={onHandleSyncTranslation}
                                                disabled={!value?.length}
                                                style={{marginTop: 15, marginBottom: 30}}
                                                label={'Перевести автоматически'}
                                                icon={<FontAwesomeIcon
                                                    width={30}
                                                    height={30}
                                                    size={'2x'}
                                                    icon={faLanguage}
                                                    style={{marginRight: 10}}
                                                />}
                                            />
                                            {
                                                translations.map(translation => {
                                                    const lang = languages.find(item => item.code === translation.code);

                                                    return lang ?
                                                        <TranslationName
                                                            key={lang.code}
                                                            nameLang={lang.name}
                                                            image={lang.image}
                                                            code={lang.code}
                                                            value={translation.name}
                                                            isDisable={translation.isDisable}
                                                            isProcessing={translation.isProcessing}
                                                            onHandleBlur={onHandleTranslation}
                                                            error={translation.error}
                                                        /> : ''
                                                })
                                            }
                                        </Panel>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                isEdit={true}
                                                urlCreateAndList={routesEnum.List_habit_category_list}
                                                urlCreateAndEdit={routesEnum.List_habit_category_edit_edit}
                                                onHandleBtn={onHandleClickUpdate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
                {
                    isLoadingData && !isUpdate ?
                        <Lottie
                            style={{width: 350, height: 350, textAlign: 'center', margin: 'auto', paddingTop: 35}}
                            width={50}
                            height={50}
                            animationData={animation}/> : ''
                }
            </div>
        </div>
    );
}
