import React, {FC, JSX} from "react";
import './table-empty.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen} from "@fortawesome/free-solid-svg-icons";

interface itemsProps {

}

export const TableEmpty: FC<itemsProps> = (props): JSX.Element => {

    return (
        <div>
            <div className="table-empty">
                <span>Нет данных</span>
                <FontAwesomeIcon
                    width={20}
                    height={20}
                    icon={faBoxOpen}
                />
            </div>
        </div>
    );
}
