import React, {FC, useEffect, useState} from "react";
import './translation.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {IError} from "../../../types/IError";
import {Editor} from "primereact/editor";
import {EditorRenderHeader} from "../../ui/EditorRenderHeader";
import {Splitter, SplitterPanel} from "primereact/splitter";

interface itemsProps {
    code: string,
    image: string,
    nameLang: string,
    value: string | null,
    isDisable: boolean,
    isProcessing: boolean,
    onHandleBlur: (e: React.FocusEvent<HTMLDivElement>, code: string) => void,
    error?: IError | null,
}

export const TranslationEditor: FC<itemsProps> = (props): JSX.Element => {
    const {code, image, nameLang, value, isDisable, isProcessing, onHandleBlur, error} = props;
    const [, setValueName] = useState<string | null>(null);

    useEffect(() => {
        setValueName(value);
    }, [value]);

    return (
        <Fieldset key={code} className={'fieldset translation-fieldset'} toggleable
                  legend={<div className={'translation-fieldset-legend'}>
                      <img src={image} alt={nameLang}/>
                      <span>{nameLang}</span>
                  </div>}>
            <div className="translations">
                <div className="translation-item">
                    <Splitter className={'splitter'}>
                        <SplitterPanel
                            className="flex align-items-center justify-content-center splitter-panel"
                        >
                            <Editor
                                className={'editor'}
                                value={value || ''}
                                onTextChange={e => setValueName(e.htmlValue)}
                                onBlur={e => onHandleBlur(e, code)}
                                headerTemplate={<EditorRenderHeader/>}
                                style={{height: '300px'}}
                            />
                            {/*<Button icon={<FontAwesomeIcon*/}
                            {/*    width={20}*/}
                            {/*    height={20}*/}
                            {/*    icon={faLanguage}*/}
                            {/*/>}/>*/}
                        </SplitterPanel>
                        <SplitterPanel
                            className="flex align-items-center justify-content-center splitter-panel"
                        >
                            <div className="result">
                                {value && <div dangerouslySetInnerHTML={{ __html: value }} />}
                            </div>
                        </SplitterPanel>
                    </Splitter>
                </div>
            </div>
        </Fieldset>

    );
}
