import React, {FC, ReactElement} from "react";
import '../input.css';

import {IError} from "../../../../types/IError";
import {InputMask as PrimeInputMask, InputMaskChangeEvent} from "primereact/inputmask";
import {v4 as uuidv4} from "uuid";

type item = {
    value: string,
    mask: string,
    id?: string,
    width?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    disabled?: boolean,
    onHandleChange?: (e: InputMaskChangeEvent) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
    maxlength?: number,
    minlength?: number,
    className?: string,
    unmask?: boolean

}

export const InputMask: FC<item> = (props): ReactElement => {
    const {
        id,
        value,
        width,
        placeholder,
        title,
        mask,
        error,
        disabled,
        className,
        maxlength,
        minlength,
        onHandleChange,
        onHandleBlur,
        onHandleFocus,
        onHandleClick,
    } = props;

    const inputId = id || uuidv4()

    return (
        <div className={'item-form'}>
            <label className="label" htmlFor={inputId}>{title}</label>
            <div className="p-inputgroup">
                <PrimeInputMask
                    id={inputId}
                    width={width}
                    className={'input ' + (className || '')}
                    mask={mask}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxlength}
                    minLength={minlength}
                    onChange={onHandleChange}
                    onBlur={onHandleBlur}
                    onFocus={onHandleFocus}
                    onClick={onHandleClick}
                />
                {/*<span className="p-inputgroup-addon">*/}
                {/*    1*/}
                {/*</span>*/}
            </div>
        </div>
    );
}
