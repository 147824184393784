import React, {FC, ReactElement} from "react";
import '../input.css';

import {IError} from "../../../../types/IError";
import {InputTextarea} from 'primereact/inputtextarea';
import {v4 as uuidv4} from "uuid";

type item = {
    value: string,
    id?: string,
    width?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    disabled?: boolean,
    onHandleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void,
    onHandleFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void,
    onHandleClick?: (e: React.MouseEvent<HTMLTextAreaElement>) => void,
    maxlength?: number,
    minlength?: number,
    className?: string,
    unmask?: boolean
    autoResize?: boolean,
    rows?: number,
    cols?: number,
}

export const Textarea: FC<item> = (props): ReactElement => {
    const {
        id,
        value,
        width,
        placeholder,
        title,
        error,
        disabled,
        className,
        maxlength,
        minlength,
        rows,
        cols,
        autoResize,
        onHandleChange,
        onHandleBlur,
        onHandleFocus,
        onHandleClick,
    } = props;

    const inputId = id || uuidv4()
    const rowsNumber = rows || 5;
    const colsNumber = cols || 15;

    return (
        <div className={'item-form ' + (error?.status || '')}>
            {
                title ?
                    <label className="label" htmlFor={inputId}>{title}</label>
                    : ''
            }
            <InputTextarea
                id={inputId}
                className={'textarea ' + (className || '')}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxlength}
                minLength={minlength}
                rows={rowsNumber}
                cols={colsNumber}
                autoResize={autoResize}
                onChange={onHandleChange}
                onBlur={onHandleBlur}
                onFocus={onHandleFocus}
                onClick={onHandleClick}
            />
        </div>
    );
}
