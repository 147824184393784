import {ITranslation} from "../../../../types/lists/ITranslation";
import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {IHabitPatternRequest} from "../../../../types/lists/habit/IHabitPattern";
import {IHabitIconCategory} from "../../../../types/lists/habit/IHabitIconCategory";

export class Pattern {
    public name!: string | null;
    public description!: string | null;
    public categoryIcon!: IHabitIconCategory | null;
    public isActive: boolean = true;
    public isNegative: boolean = false;
    public sort: number = 500;
    public translations!: ITranslation[];
    public errors: IError[] = [];

    public getErrors(isError: boolean = true): IError[] {
        return this.errors.filter(item => item.status === (isError ? statusFormActionEnum.Error : statusFormActionEnum.Success));
    }

    public getRequestData(): IHabitPatternRequest {
        if (this.getErrors().length)
            throw new Error("Ошибка: некорректные данные");

        return {
            name: this.name as string,
            description: this.description as string,
            categoryIconId: this.categoryIcon?.id as string,
            translations: this.translations,
            sort: this.sort,
            isActive: this.isActive,
            isNegative: this.isNegative,
        };
    }
}
