import React, {useEffect, useState} from "react";
import './home.css';

import {useTranslation} from "react-i18next";
import {HomeStatNewUserSection} from "./sections/HomeStatNewUserSection";
import {Container, Row, Col} from 'react-grid-system';
import {Chart} from "primereact/chart";
import {HomeStatDeviceSection} from "./sections/HomeStatDeviceSection";
import {servicePlatform} from "../../services/statistics/StatisticsService";
import {IStatPlatform} from "../../types/statistics/IStatistics";

export function Home() {
    const {t} = useTranslation();

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [platform, setPlatform] = useState<IStatPlatform | null>(null);
    const [loadingPlatform, setLoadingPlatform] = useState(true);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        servicePlatform().then(response => {
            setLoadingPlatform(false);

            if (!response.success)
                return;

            setPlatform(response.data);
        }).catch(e => {
            setLoadingPlatform(false);
        })
    }, [isInitialLoad]);

    return (
        <div className={'section-content section-page-home'}
             style={{position: 'relative', width: '100%', height: '100%'}}>
            <div>
                <Container>
                    <Row>
                        <Col sm={8}>
                            <div style={{
                                width: '100%',
                                height: 370,
                                background: '#2f4159',
                                padding: '15px 20px',
                                borderRadius: 5,
                                boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                                position: 'relative'
                            }}>
                                <HomeStatNewUserSection/>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={{
                                width: '100%',
                                background: '#2f4159',
                                marginBottom: 25,
                                padding: 10,
                                color: '#7b869a',
                                boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)'
                            }}>
                                Платформы (по запросам)
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <div style={{
                                        width: '100%',
                                        background: '#2f4159',
                                        padding: '15px 20px',
                                        borderRadius: 5,
                                        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                                        position: 'relative'
                                    }}>
                                        <div>
                                            <div className="block-items" style={{textAlign: 'center'}}>
                                                <div className="device-item">
                                                    <div className="icon"
                                                         style={{
                                                             marginBottom: 30,
                                                             marginTop: 10,
                                                             display: 'inline-flex'
                                                         }}>
                                                        <img style={{width: 80, height: 80}}
                                                             src="/assets/platforms/ios.svg"
                                                             alt=""/>
                                                    </div>
                                                    <div className="stat"
                                                         style={{width: '100%', position: 'relative', marginBottom: 5}}>
                                                        <HomeStatDeviceSection
                                                            value={platform?.ios || 0}
                                                            maxValue={platform?.all || 0}
                                                            percent={platform?.iosPercent || 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div style={{
                                        width: '100%',
                                        background: '#2f4159',
                                        padding: '15px 20px',
                                        borderRadius: 5,
                                        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
                                        position: 'relative'
                                    }}>
                                        <div>
                                            <div className="block-items" style={{textAlign: 'center'}}>
                                                <div className="device-item">
                                                    <div className="icon"
                                                         style={{
                                                             marginBottom: 30,
                                                             marginTop: 10,
                                                             display: 'inline-flex'
                                                         }}>
                                                        <img style={{width: 80, height: 80}}
                                                             src="/assets/platforms/android.svg"
                                                             alt=""/>
                                                    </div>
                                                    <div className="stat"
                                                         style={{width: '100%', position: 'relative', marginBottom: 5}}>
                                                        <HomeStatDeviceSection
                                                            value={platform?.android || 0}
                                                            maxValue={platform?.all || 0}
                                                            percent={platform?.androidPercent || 0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
