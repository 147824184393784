import React from 'react';

import {Index} from "./pages";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/vela-blue/theme.css";
import "primeicons/primeicons.css";

function App() {
  return (
      <Index />
  );
}

export default App;
