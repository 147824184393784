import React, {useEffect, useRef, useState} from "react";
import '../../../../styles/list.css';

import {useNavigate, useParams} from 'react-router-dom';
import {Col, Container, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome, faLanguage} from "@fortawesome/free-solid-svg-icons";

import {IError} from "../../../../../types/IError";
import {Toast} from "primereact/toast";
import Lottie from "lottie-react";
import animation from "../../../../../animation/lottie/animation.json";
import {routesEnum} from "../../../../../enums/routesEnum";
import {actionBtn, FormSplitButton} from "../../../../../components/ui/buttons/FormSplitButton";
import {InputNumber} from "../../../../../components/ui/form/inputNumber/InputNumber";
import {Input} from "../../../../../components/ui/form/input/Input";
import {LottieLoadingSuccessfully} from "../../../../../components/ui/animations/LottieLoadingSuccessfully";
import {useTranslation} from "react-i18next";
import {ITranslation} from "../../../../../types/lists/ITranslation";
import {actionSplitBtnEnum} from "../../../../../enums/actionSplitBtnEnum";
import {Breadcrumb} from "../../../../../components/ui/breadcrumb/Breadcrumb";
import {ILanguage} from "../../../../../types/lang/ILanguage";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {TranslationName} from "../../../../../components/business/translation/TranslationName";
import {IHabitIconCategory} from "../../../../../types/lists/habit/IHabitIconCategory";
import {Dropdown} from "../../../../../components/ui/form/dropdown/Dropdown";
import {Textarea} from "../../../../../components/ui/form/textarea/Textarea";
import {ToggleButton} from "primereact/togglebutton";
import {Tag} from "primereact/tag";
import {
    serviceHabitPatternById,
    serviceHabitPatternUpdate
} from "../../../../../services/lists/habit/habitPatternService";
import {serviceHabitIconCategoryList} from "../../../../../services/lists/habit/habitIconCategoryService";
import {
    initializeLanguagesAndTranslations,
    syncAllTranslations,
    updateTranslation,
    updateTranslationOnBlur,
} from "../../../../../utils/translationsUtils";
import {validateNumber} from "../../../../../helpers/validateFormatHelper";
import {PatternBuilder} from "../../../../../classes/business/habits/pattern/PatternBuilder";

const patternBuilder = new PatternBuilder();

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string, habitCategoryEditLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.List_habit_pattern_list
    },
    {
        label: habitCategoryEditLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function HabitPatternEdit() {
    const {t} = useTranslation();

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const paramId = useParams().id;

    const [value, setValue] = useState<string>('');
    const [valueError, setValueError] = useState<IError | null>(null);
    const [description, setDescription] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<IError | null>(null);
    const [sort, setSort] = useState<number>(500);
    const [translations, setTranslations] = useState<ITranslation[]>([]);
    const [, setTranslationError] = useState<IError | null>(null);
    const [selectedCategoryIcon, setSelectedCategoryIcon] = useState<IHabitIconCategory | null>(null);
    const [selectedCategoryIconError, setSelectedCategoryIconError] = useState<IError | null>(null);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isNegative, setIsNegative] = useState<boolean>(false);

    const [categoryIcons, setCategoryIcons] = useState<IHabitIconCategory[]>([]);

    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [nameCodeLang, setNameCodeLang] = useState<string>('');
    const [descCodeLang, setDescCodeLang] = useState<string>('');

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);

    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    useEffect(() => {
        initializeLanguagesAndTranslations(setLanguages, setTranslations);
    }, []);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        if (!paramId)
            return;

        setIsLoadingData(true);
        Promise.all([serviceHabitPatternById(paramId), serviceHabitIconCategoryList({limit: 'all'})])
            .then(([responsePattern, responseCategoryIcon]) => {
                setIsLoadingData(false);

                if (responseCategoryIcon?.success) {
                    setCategoryIcons(responseCategoryIcon.data || []);
                } else console.log('Ошибка');

                if (responsePattern?.success) {
                    const {data} = responsePattern;
                    setValue(data?.name || '');
                    setDescription(data?.description || '');
                    setSelectedCategoryIcon(data?.icon || null);
                    setSort(data?.sort || 500);
                    setIsActive(data?.isActive || false);
                    setIsNegative(data?.isNegative || false);
                    const updatedTranslations = translations.map(item => {
                        const translationData = data?.translations?.[item.code];

                        if (translationData && translationData.name)
                            return {...item, name: translationData.name, description: translationData.description};
                        else
                            return item;
                    });
                    setTranslations(updatedTranslations);
                } else console.log('Ошибка');

            })
            .catch((error) => {
                setIsLoadingData(false);
                console.error('Ошибка загрузки данных:', error);
            });
    }, [paramId, isInitialLoad]);

    const onHandleClickUpdate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void | null => {
        patternBuilder.setName(value)
            .setCategoryIcon(selectedCategoryIcon)
            .setTranslation(translations)
            .setDescription(description)
            .setSort(sort)
            .setIsActive(isActive)
            .setIsNegative(isNegative);

        setValueError(patternBuilder.validateName());
        setDescriptionError(patternBuilder.validateDescription());
        setTranslationError(patternBuilder.validateTranslation());
        setSelectedCategoryIconError(patternBuilder.validateCategoryIcon());

        const pattern = patternBuilder.get();
        setTranslations(pattern.translations);

        if (pattern.getErrors().length || !paramId)
            return;

        setIsUpdate(true);

        serviceHabitPatternUpdate(paramId, pattern.getRequestData()).then(response => {
            setIsUpdate(false);

            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Обновление',
                    detail: 'Данные не были обновлены',
                    life: 3000,
                });

                return null;
            }

            setIsCompletion(true);

            setTimeout(() => {
                setIsCompletion(false);

                if (action.action !== actionSplitBtnEnum.CREATE_OR_EDIT && action.url) {
                    navigate(action.action === actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT ? action.url + `/${paramId}` : action.url);
                }

            }, 3000);

            setValueError(null);
            setDescriptionError(null);
            setSelectedCategoryIconError(null);

            const updatedTranslations = translations.map(item => {
                return {...item, name: '', error: null, errorDesc: null, isDisable: false, isProcessing: false};
            });
            setTranslations(updatedTranslations);

            toast.current?.show({
                severity: 'success',
                summary: 'Обновление',
                detail: 'Данные успешно обновлены',
                life: 3000,
            });

            return null;
        });
    }

    const optionTemplate = (item: IHabitIconCategory): JSX.Element => {
        return <div className={"option-item" + (selectedCategoryIcon?.id === item.id ? ' selected' : '')}>
            <div className="option-item-content">
                <div className="icon">
                    <img src={typeof item.icon === 'string' ? item.icon : undefined} alt={item.name}/>
                </div>
                <div className="name">
                    <div>{item.name}</div>
                    <div>
                        <Tag style={{background: item.category?.color || undefined}} value={item.category?.name}/>
                    </div>
                </div>
            </div>
        </div>
    }

    const onHandleValueTranslationBlur = (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, isDesc: boolean = false): void => {
        updateTranslationOnBlur(e.target.value, setValue, setNameCodeLang, setValueError, translations, setTranslations, isDesc, setDescCodeLang);
    }

    const onHandleTranslation = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, code: string, isDesc: boolean = false) => {
        updateTranslation(e.target.value, code, translations, setTranslations, isDesc);
    }

    const onHandleSyncTranslation = async () => {
        await syncAllTranslations(value, description, nameCodeLang, descCodeLang, translations, setTranslations, true);
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_habit_pattern'), t('label_habit_pattern_editing'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isUpdate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isUpdate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingData && !isUpdate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Dropdown
                                            isLocalFilter={true}
                                            width={'100%'}
                                            value={selectedCategoryIcon}
                                            options={categoryIcons}
                                            optionTemplate={optionTemplate}
                                            onHandleChange={e => setSelectedCategoryIcon(e.value)}
                                            title={'Выберите иконку *'}
                                            placeholder={'Выберите иконку привычки'}/>
                                    </Col>
                                    <Col sm={10}>
                                        <Input
                                            maxlength={255}
                                            value={value}
                                            title={'Наименование *'}
                                            onHandleChange={e => setValue(e.target.value)}
                                            onHandleBlur={onHandleValueTranslationBlur}
                                            error={valueError}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <InputNumber
                                            title={'Сортировка'}
                                            value={sort}
                                            onHandleChange={(e) => setSort(e.value || 0)}
                                            onHandleBlur={e => setSort(validateNumber(e.target.value))}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Textarea
                                            title={'Описание'}
                                            value={description}
                                            onHandleChange={e => setDescription(e.target.value)}
                                            onHandleBlur={e => onHandleValueTranslationBlur(e, true)}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Активность (Да)"
                                            offLabel="Активность (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isActive}
                                            className="w-9rem"
                                            onChange={e => setIsActive(e.value)}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <ToggleButton
                                            style={{marginTop: 25}}
                                            onLabel="Негативная привычка (Да)"
                                            offLabel="Негативная привычка (Нет)"
                                            onIcon="pi pi-check"
                                            offIcon="pi pi-times"
                                            checked={isNegative}
                                            className="w-9rem"
                                            onChange={e => setIsNegative(e.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Panel header="Переводы" toggleable style={{marginTop: 25}}>
                                            <Button
                                                onClick={onHandleSyncTranslation}
                                                disabled={!value?.length}
                                                style={{marginTop: 15, marginBottom: 30}}
                                                label={'Перевести автоматически'}
                                                icon={<FontAwesomeIcon
                                                    width={30}
                                                    height={30}
                                                    size={'2x'}
                                                    icon={faLanguage}
                                                    style={{marginRight: 10}}
                                                />}
                                            />
                                            {
                                                translations.map(translation => {
                                                    const lang = languages.find(item => item.code === translation.code);

                                                    return lang ?
                                                        <TranslationName
                                                            key={lang.code}
                                                            nameLang={lang.name}
                                                            image={lang.image}
                                                            code={lang.code}
                                                            value={translation.name}
                                                            valueDescription={translation.description || ''}
                                                            isDisable={translation.isDisable}
                                                            isProcessing={translation.isProcessing}
                                                            onHandleBlur={(e, code) => onHandleTranslation(e, code)}
                                                            onHandleDescBlur={(e, code) => onHandleTranslation(e, code, true)}
                                                        /> : ''
                                                })
                                            }
                                        </Panel>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                isEdit={true}
                                                urlCreateAndList={routesEnum.List_habit_pattern_list}
                                                urlCreateAndEdit={routesEnum.List_habit_pattern_edit_edit}
                                                onHandleBtn={onHandleClickUpdate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
                {
                    isLoadingData && !isUpdate ?
                        <Lottie
                            style={{width: 350, height: 350, textAlign: 'center', margin: 'auto', paddingTop: 35}}
                            width={50}
                            height={50}
                            animationData={animation}/> : ''
                }
            </div>
        </div>
    );
}
