import {AbstractBuilder} from "../../AbstractBuilder";
import {IError} from "../../../../types/IError";
import {ErrorHelper} from "../../../../helpers/ErrorHelper";
import {TrustedUser} from "./TrustedUser";
import {ITrusted, ITrustedUser, IWithoutTrustedUser} from "../../../../types/users/ITrustedUser";
import {Simulate} from "react-dom/test-utils";
import wheel = Simulate.wheel;

export class TrustedUserBuilder extends AbstractBuilder {
    private user!: TrustedUser;

    constructor() {
        super();
        this.create();
    }

    protected create(): void {
        this.user = new TrustedUser();
    }

    public setUsers(value: ITrusted[]): this {
        this.user.users = value;

        return this;
    }

    public validateUsers(): IError {
        return !this.user.users?.length
            ? ErrorHelper.getMessageError('Пользователи', 'Вы не выбрали пользователей(я)', 'trustedUser.users', true)
            : ErrorHelper.getMessageError('Пользователи', 'Данные выбраны верно', 'trustedUser.users');
    }

    public setPassword(value: string | null): this {
        this.user.password = value;

        return this;
    }

    public validatePassword(): IError {
        if (!this.user.isAuthAdminPanel)
            ErrorHelper.getMessageError('Пароль', 'Данные введены верно', 'trustedUser.password');

        return !this.user.password?.length
            ? ErrorHelper.getMessageError('Пароль', 'Обязательно для заполнения', 'trustedUser.password', true)
            : ErrorHelper.getMessageError('Пароль', 'Данные введены верно', 'trustedUser.password');
    }

    public setRePassword(value: string|null) : this {
        this.user.rePassword = value;

        return this;
    }

    public validateRePassword() : IError {
        if (!this.user.isAuthAdminPanel)
            return ErrorHelper.getMessageError('Пароль еще раз', 'Данные введены верно', 'trustedUser.rePassword');

        if (!this.user.rePassword?.length)
            return ErrorHelper.getMessageError('Пароль еще раз', 'Обязательно для заполнения', 'trustedUser.rePassword', true);

        if (this.user.password !== this.user.rePassword)
            return ErrorHelper.getMessageError('Пароли', 'Пароли не совпадают', 'trustedUser.rePassword', true);

        return ErrorHelper.getMessageError('Пароль еще раз', 'Данные введены верно', 'trustedUser.rePassword');
    }

    public setIsActive(value: boolean|null): this {
        this.user.isActive = value || true;

        return this;
    }

    public setIsAuthAdminPanel(value: boolean|null) : this {
        this.user.isAuthAdminPanel = value || false;

        return this;
    }

    public setCode(value: string|null) : this {
        this.user.code = value;

        return this;
    }

    public validateCode() : IError {
        return !this.user.code?.length
            ? ErrorHelper.getMessageError('Код', 'Обязательно для заполнения', 'trustedUser.code', true)
            : ErrorHelper.getMessageError('Код', 'Данные введены верно', 'trustedUser.code');
    }

    private validate(): void {
        this.user.errors.push(this.validateUsers());
        this.user.errors.push(this.validateCode());

        if (this.user.isAuthAdminPanel) {
            this.user.errors.push(this.validatePassword());
            this.user.errors.push(this.validateRePassword());
        }
    }

    public get(): TrustedUser {
        const user = this.user;
        this.validate();
        this.create();

        return user;
    }
}
