import {IValidatePassword} from "../types/users/IValidatePasswordError";

export class ValidateHelper {
    public static checkPasswordRequirements(value: string) : IValidatePassword {
        let errorObject = {
            smallLetter: false,
            capitalLetter: false,
            specialSymbol: false,
            number: false,
            minLength: false,
        };

        if (/(?=.*[a-z])/.test(value)) {
            errorObject.smallLetter = true;
        }
        if (/(?=.*[A-Z])/.test(value)) {
            errorObject.capitalLetter = true;
        }
        if (/(?=.*[_$!@#%^&*()\-+=?])/.test(value)) {
            errorObject.specialSymbol = true;
        }
        if (/(?=.*\d)/.test(value)) {
            errorObject.number = true;
        }
        if (value.length >= 8) {
            errorObject.minLength = true;
        }

        return errorObject;
    }
}
