import {IResponse} from "../../types/server/IServer";
import {ILoginData} from "../../types/auth/IAuth";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";

interface IData {
    phone: string;
    password: string;
}

export async function serviceAuthLogin(params: IData): Promise<IResponse<ILoginData, null>> {
    const uri = '/admin/auth';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<ILoginData, null>(method, uri, params, false);
    } catch (error) {
        throw error;
    }
}

export async function serviceAuthLoginTest(params: {phone: string, codeDevice: string}): Promise<IResponse<ILoginData, null>> {
    const uri = '/auth/auth-sms';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<ILoginData, null>(method, uri, params, false);
    } catch (error) {
        throw error;
    }
}
