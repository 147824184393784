import React, {FC, JSX} from "react";
import './color-picker.css';

import {
    ColorPicker as ColorPickerPrime,
    ColorPickerChangeEvent,
    ColorPickerHSBType,
    ColorPickerRGBType
} from 'primereact/colorpicker';

interface itemsProps {
    value: string | ColorPickerRGBType | ColorPickerHSBType,
    format?: 'hex' | 'rgb' | 'hsb',
    disable?: boolean,
    onChange: (e: ColorPickerChangeEvent) => void,
}

export const ColorPicker: FC<itemsProps> = (props): JSX.Element => {
    const {value, format, disable, onChange} = props;
    return (
        <div className={'color-picker-content'}>
            <ColorPickerPrime
                inputClassName={'color-picker'}
                format={format}
                value={value}
                disabled={disable}
                onChange={onChange}
            />
        </div>
    );
}
