import {ITranslation} from "../../../../types/lists/ITranslation";
import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {IGlobalSettingRequest} from "../../../../types/setting/IGlobalSetting";

export class GlobalSetting {
    public key!: string | null;
    public oldKey!: string;
    public name!: string | null;
    public value!: string;
    public type!: string | null;
    public translations!: ITranslation[];
    public errors: IError[] = [];

    public getErrors(isError: boolean = true): IError[] {
        return this.errors.filter(item => item.status === (isError ? statusFormActionEnum.Error : statusFormActionEnum.Success));
    }

    public getRequestData(): IGlobalSettingRequest {
        if (this.getErrors().length)
            throw new Error("Ошибка: некорректные данные");

        return {
            key: this.key as string,
            oldKey: this.oldKey,
            name: this.name as string,
            value: this.value,
            type: this.type as string,
            translations: this.translations,
        };
    }
}
