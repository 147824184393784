import React, {useEffect, useRef, useState} from "react";
import './menu-bar.css';

import {motion} from "framer-motion";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {useAppSelector} from "../../../../../../hooks/redux";
import {useActions} from "../../../../../../hooks/actions";
import {OverlayPanel} from "primereact/overlaypanel";
import {Dropdown} from 'primereact/dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars
} from "@fortawesome/free-solid-svg-icons";

import {OverlayElSearch} from "./overlayElements/OverlayElSearch";
import {ILanguage} from "../../../../../../types/lang/ILanguage";

export function MenuBarStart() {
    const {languagesStore, selectedLanguageStore} = useAppSelector(state => state.appSliceReducer);
    const {setSelectedLanguageStore} = useActions().appActions;

    const [search, setSearch] = useState('');
    const {openedLeft} = useAppSelector(state => state.drawer);
    const {setLeftDrawer} = useActions().drawerAppActions;
    const opSearch = useRef<OverlayPanel>(null);

    useEffect(() => {

    }, [search, openedLeft]);

    return (
        <div className={'menu-main-top'}>
            <motion.div className={'menu-top'} animate={{width: openedLeft ? 237 : 70}}
                        transition={{type: "spring"}}>
                {openedLeft && <span className={'menu-top-logo'}>
                    <div>
                        <span>H</span><span>umanitool</span>
                    </div>
                </span>}
                <Button
                    style={{
                        position: 'absolute',
                        top: openedLeft ? -3 : -20,
                        right: 10,
                        marginLeft: !openedLeft ? 7 : ''
                    }}
                    onClick={() => {
                        setLeftDrawer(!openedLeft)
                    }}
                    className="mr-2">
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faBars}
                    />
                </Button>
            </motion.div>
            {/*<div className="search">*/}
            {/*    <span className="p-input-icon-right">*/}
            {/*        <i className="pi pi-spin pi-spinner"/>*/}
            {/*        <InputText*/}
            {/*            placeholder={lang.app.search}*/}
            {/*            onFocus={e => opSearch.current?.toggle(e)}*/}
            {/*            onChange={(e) => {*/}
            {/*                const value = e.target.value;*/}
            {/*                setSearch(value);*/}
            {/*            }}*/}
            {/*            value={search}*/}
            {/*        />*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div className="language">
                <Dropdown
                    appendTo={'self'}
                    className={"language-dropdown"}
                    panelClassName={'language-dropdown-open-content'}
                    value={selectedLanguageStore}
                    options={languagesStore}
                    onChange={(e: { value: ILanguage }) => setSelectedLanguageStore(e.value)}
                    optionLabel="name"
                />
            </div>
            <OverlayPanel
                appendTo={'self'}
                ref={opSearch}
                id="overlay_panel"
                style={{width: "450px", height: '450px'}}
                className="overlaypanel-demo overlay-panel overlay-panel-search"
            >
                <OverlayElSearch/>
            </OverlayPanel>
        </div>
    );
}
