import React, {FC, JSX, useState} from "react";
import './form-splite-btn.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SplitButton} from "primereact/splitbutton";
import {faPlus, faListUl, faPenToSquare, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {actionSplitBtnEnum} from "../../../enums/actionSplitBtnEnum";

export interface actionBtn {
    name: string,
    action: actionSplitBtnEnum,
    icon: IconDefinition,
    url: string | null,
}

interface itemsProps {
    onHandleBtn: (e: React.MouseEvent<HTMLElement>, action: actionBtn) => void,
    urlCreateAndList?: string,
    urlCreateAndEdit?: string,
    isEdit?: boolean,
    isList?: boolean,
}

const icon = (icon: IconDefinition) : JSX.Element => {
    return <FontAwesomeIcon
        style={{marginRight: 10}}
        className={'icon'}
        width={20}
        height={20}
        icon={icon}
    />
};

export const FormSplitButton: FC<itemsProps> = (props): JSX.Element => {
    const {onHandleBtn, urlCreateAndList, urlCreateAndEdit, isEdit, isList} = props;

    const defaultAction = isEdit
        ?
        {name: 'Сохранить', action: actionSplitBtnEnum.CREATE_OR_EDIT, icon: faFloppyDisk, url: null}
        :
        {name: 'Создать', action: actionSplitBtnEnum.CREATE_OR_EDIT, icon: faPlus, url: null}

    const [action, setAction] = useState<actionBtn>(defaultAction);

    let items;

    if (isEdit) {
        items = [
            {
                label: 'Сохранить',
                icon: icon(faFloppyDisk),
                command: () => {
                    setAction({name: 'Сохранить', action: actionSplitBtnEnum.CREATE_OR_EDIT, icon: faFloppyDisk, url: null});
                }
            },
        ];

        if (isList === undefined)
            items.push(
                {
                    label: 'Сохранить и вернуться к списку',
                    icon: icon(faListUl),
                    command: () => {
                        setAction({name: 'Сохранить и вернуться к списку', action: actionSplitBtnEnum.SAVE_OR_EDIT_BACK_LIST, icon: faListUl, url: urlCreateAndList || ''});
                    }
                },
            );
    }
    else {
        items = [
            {
                label: 'Создать',
                icon: icon(faPlus),
                command: () => {
                    setAction({name: 'Создать', action: actionSplitBtnEnum.CREATE_OR_EDIT, icon: faPlus, url: null});
                }
            },
            {
                label: 'Создать и редактировать',
                icon: icon(faPenToSquare),
                command: () => {
                    setAction({name: 'Создать и редактировать', action: actionSplitBtnEnum.SAVE_OR_EDIT_AND_EDIT, icon: faPenToSquare, url: urlCreateAndEdit || ''});
                }
            },
        ];

        if (isList === undefined)
            items.push(
                {
                    label: 'Создать и вернуться к списку',
                    icon: icon(faListUl),
                    command: () => {
                        setAction({name: 'Создать и вернуться к списку', action: actionSplitBtnEnum.SAVE_OR_EDIT_BACK_LIST, icon: faListUl, url: urlCreateAndList || ''});
                    }
                },
            );
    }

    return (

        <SplitButton
            appendTo={'self'}
            className={'form-split-btn'}
            label={action.name}
            icon={icon(action.icon)}
            onClick={(e) => onHandleBtn(e, action)}
            model={items}
        />
    );
}
