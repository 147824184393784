import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import './login.css';
import {useActions} from "../../../hooks/actions";
import {useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {Password} from 'primereact/password';
import {Button} from "primereact/button";
import {
    serviceAuthLogin, serviceAuthLoginTest,
} from "../../../services/auth/auth";
import {Dropdown} from 'primereact/dropdown';
import {useAppSelector} from "../../../hooks/redux";
import {Toast} from 'primereact/toast';
import {ILanguage} from "../../../types/lang/ILanguage";
import { useTranslation } from 'react-i18next';

export function Login() {
    const { t } = useTranslation();

    const {setSelectedLanguageStore} = useActions().appActions;
    const {languagesStore, selectedLanguageStore} = useAppSelector(state => state.appSliceReducer);

    const toast = useRef<Toast>(null);

    const navigate = useNavigate();

    const [emailOldValue, setEmailOldValue] = useState<string>('');
    const [isCheckLoadingEmail, setIsCheckLoadingEmail] = useState<boolean>(false);

    const [phoneOldValue, setPhoneOldValue] = useState<string>('');
    const [isCheckLoadingPhone, setIsCheckLoadingPhone] = useState<boolean>(false);

    const [errorMessageSign, setErrorMessageSign] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string | null>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
    const [classLoading, setClassLoading] = useState<string>('');

    const {setAuthUser} = useActions().appAuthActions;

    useEffect(() => {
        serviceAuthLoginTest({phone: '79130864501', codeDevice: "test"}).then(response => {
            console.log(response);
        })
    }, []);

    const onHandleSign = () => {
        // signErrors();
        //
        // if (!email.length || !password.length) {
        //     return;
        // }
        //
        // setIsActionLoading(true);
        // setClassLoading('loading');
        //
        serviceAuthLogin({phone, password}).then(response => {
            setIsActionLoading(false)
            console.log(response);
            if (!response.success) {
                setErrorMessageSign('Не верный телефон или пароль');

                return null;
            }

            setClassLoading('success');

            const data = response?.data;

            if (data?.token) {
                setAuthUser(data);

                navigate(-1);
            }
        });
    }

    const selectedCountryTemplate = (option: ILanguage) => {
        return (
            <img
                alt={option.name}
                src={option.image}
                className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            />
        );
    };

    const countryOptionTemplate = (option: ILanguage) => {
        return (
            <img
                alt={option.name}
                src={option.image}
                className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            />
        );
    };

    return (
        <div>
            <div className="background-area">
                <Toast className={'toast'} ref={toast}/>
                <div className="figures">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
            <div id="sign">
                <div className={'container'}>
                    <div className={'card'}>
                        <div className={"sign-main " + classLoading}>
                            <div className="form-sign">
                                <div className="content-sign">
                                    <div>
                                        <div className="item">
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Dropdown
                                                    appendTo={'self'}
                                                    value={selectedLanguageStore}
                                                    onChange={e => setSelectedLanguageStore(e.value)}
                                                    options={languagesStore}
                                                    optionLabel="name"
                                                    placeholder={t('label_choose_language')}
                                                    valueTemplate={selectedCountryTemplate}
                                                    itemTemplate={countryOptionTemplate}
                                                    className="dropdown-lang"
                                                />
                                            </div>
                                        </div>
                                        <div className={"item " + (phoneError ? 'error' : '')}>
                                            <label htmlFor="sign-phone">{t('label_phone_number')}</label>
                                            <InputMask
                                                mask={'+7-999-999-99-99'}
                                                value={phone}
                                                id={'sign-phone'}
                                                placeholder={t('form:label_enter_phone_number')}
                                                onChange={(e) => setPhone(e.value || '')}
                                                onBlur={e => setPhone(e.target.value?.replace(/\D/g, ''))}
                                            />
                                            <small>{phoneError}</small>
                                        </div>
                                        <div className={"item " + (passwordError ? 'error' : '')}>
                                            <label htmlFor="sign-password">{t('label_password')}</label>
                                            <Password
                                                id={'sign-password'}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                toggleMask={true}
                                                placeholder={t('form:label_enter_password')}
                                                feedback={false}
                                            />
                                            <small>{passwordError}</small>
                                        </div>
                                        {
                                            errorMessageSign ?
                                                <div className="message-error">
                                                    <p style={{textAlign: 'center'}}>{errorMessageSign}</p>
                                                </div> : null
                                        }
                                        <div className={"content-btn-sign " + classLoading}>
                                            <Button
                                                onClick={onHandleSign}
                                                loading={isActionLoading}
                                                className={'btn'}
                                                label={t('label_sign_in')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
