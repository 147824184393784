import {IDefaultParams, IResponse} from "../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";
import {IGlobalSetting, IGlobalSettingRequest} from "../../types/setting/IGlobalSetting";

export async function serviceGlobalAppSettingList(params?: IDefaultParams): Promise<IResponse<IGlobalSetting[], null>> {
    const uri = '/app/init-settings';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IGlobalSetting[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceGlobalAppSettingById(keyOrId: string): Promise<IResponse<IGlobalSetting, null>> {
    const uri = '/app/init-settings/' + keyOrId;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IGlobalSetting, null>(method, uri);
    } catch (error) {
        throw error;
    }
}

export async function serviceGlobalAppSettingCreate(data: IGlobalSettingRequest): Promise<IResponse<IGlobalSetting, null>> {
    const uri = '/admin/app/init-settings';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<IGlobalSetting, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceGlobalAppSettingUpdate(id: string, data: IGlobalSettingRequest): Promise<IResponse<null, null>> {
    const uri = '/admin/app/init-settings/' + id;
    const method: THttpMethod = 'put';

    try {
        return await makeServiceRequest<null, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceGlobalAppSettingDelete(id: string): Promise<IResponse<null, null>> {
    const uri = '/admin/app/init-settings/' + id;
    const method: THttpMethod = 'delete';

    try {
        return await makeServiceRequest<null, null>(method, uri);
    } catch (error) {
        throw error;
    }
}
