import React, {FC, JSX, ReactElement} from "react";
import '../input.css';

import {IError} from "../../../../types/IError";
import {InputText} from "primereact/inputtext";
import {v4 as uuidv4} from "uuid";
import {Tooltip} from "primereact/tooltip";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {KeyFilterType} from "primereact/keyfilter";
import {Password} from "primereact/password";
import {Divider} from "primereact/divider";
import {ValidateHelper} from "../../../../helpers/ValidateHelper";

type item = {
    feedback?: boolean,
    style?: any,
    classMain?: string,
    titleColor?: string,
    value: string,
    id?: string,
    width?: string,
    placeholder?: string,
    title?: string,
    error?: IError | null,
    disabled?: boolean,
    onHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onHandleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onHandleClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
    maxlength?: number,
    minlength?: number,
    className?: string,
    unmask?: boolean,
    icon?: {
        value: JSX.Element,
        position: 'left' | 'right',
    },
    header?: JSX.Element,
    footer?: JSX.Element,
    keyfilter?: KeyFilterType,
    ref?: any,
}

const headerTemplateDefault = <div className="font-bold mb-3">Уровень надежности</div>;
const footerTemplateDefault = (value: string) => {
    const valParam = ValidateHelper.checkPasswordRequirements(value);

    return <div>
        <Divider/>
        <p className="mt-2">Правило:</p>
        <ul style={{listStyle: 'inside'}} className="pl-2 ml-2 mt-0 line-height-3">
            <li style={{color: valParam.smallLetter ? 'green' : undefined}}>Хотя-бы одна строчная буква</li>
            <li style={{color: valParam.capitalLetter ? 'green' : undefined}}>Хотя-бы одна заглавная буква</li>
            <li style={{color: valParam.specialSymbol ? 'green' : undefined}}>Хотя-бы один символ (_, $, ! и тд)</li>
            <li style={{color: valParam.number ? 'green' : undefined}}>Хотя-бы одна цифра</li>
            <li style={{color: valParam.minLength ? 'green' : undefined}}>Минимум 8 символов</li>
        </ul>
    </div>
}

export const InputPassword: FC<item> = (props): ReactElement => {
    const {
        style,
        classMain,
        id,
        value,
        width,
        placeholder,
        title,
        error,
        disabled,
        className,
        maxlength,
        minlength,
        keyfilter,
        onHandleChange,
        onHandleBlur,
        onHandleFocus,
        onHandleClick,
        icon,
        ref,
        header,
        footer,
        feedback
    } = props;

    const inputId = id || uuidv4();

    const errorStyle = () => {
        if (error?.status === 'error') {
            return {style: 'invalid', icon: 'fas fa-exclamation-circle'};
        } else if (error?.status === 'success') {
            return {style: 'valid', icon: 'fas fa-check'};
        }
    }

    const getStatusError = () => {
        if (error?.status === 'error' || error?.status === 'success') {
            const randString = uuidv4();
            return (
                <div>
                    <FontAwesomeIcon
                        className={`icon i-error custom-tooltip-input-${randString} ${errorStyle()?.style} ${errorStyle()?.icon}`}
                        width={20}
                        height={20}
                        icon={faCircleExclamation}
                    />
                    <Tooltip target={".custom-tooltip-input-" + randString}>
                        <p>{error?.message}</p>
                    </Tooltip>
                </div>
            );
        }
    }

    return (
        <div className={'item-form ' + (error?.status || '') + (classMain ? (' ' + classMain) : '')}>
            {
                title ?
                    <label className={"label " + (errorStyle()?.style)} htmlFor={inputId}>{title}</label>
                    : ''
            }
            <div className="p-inputgroup">
               <span className={"p-input-icon " + (icon?.value ? ('p-input-icon-' + icon.position) : '')}>
                    {
                        icon?.value || ''
                    }
                   <Password
                       style={style}
                       value={value}
                       onChange={onHandleChange}
                       onBlur={onHandleBlur}
                       onFocus={onHandleFocus}
                       onClick={onHandleClick}
                       id={inputId}
                       width={width}
                       className={'input ' + (className || '')}
                       header={feedback ? (header || headerTemplateDefault) : undefined}
                       footer={feedback ? (footer || footerTemplateDefault(value)) : undefined}
                       placeholder={placeholder}
                       disabled={disabled}
                       maxLength={maxlength}
                       minLength={minlength}
                       keyfilter={keyfilter}
                       strongLabel={'Сильный пароль'}
                       mediumLabel={'Средний пароль'}
                       weakLabel={'Слабый пароль'}
                       toggleMask
                       feedback={feedback}
                       ref={ref}
                   />
                   {
                       error?.status === statusFormActionEnum.Error ?
                           <div className="error-icon">
                               {getStatusError()}
                           </div> : ''
                   }
               </span>
            </div>
            {
                error?.status === statusFormActionEnum.Error ?
                    <small style={{color: '#ef9a9a', marginLeft: 5}}>{error.message}</small> : ''
            }
        </div>
    );
}
