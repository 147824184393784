import {v4 as uuidv4} from "uuid";

export function loadingEmptyTableItemsHelper(maxItems: number): { id: string }[] {
    let items = [];

    for (let i = 0; i < maxItems; i++) {
        items.push({
            id: uuidv4(),
        });
    }

    return items;
}
