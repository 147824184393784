import {ITranslation} from "../../../../types/lists/ITranslation";
import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {IHabitCategoryRequest} from "../../../../types/lists/habit/IHabitCategory";

export class Category {
    public name!: string | null;
    public color!: string | null;
    public isActive: boolean = true;
    public sort: number = 500;
    public translations!: ITranslation[];
    public errors: IError[] = [];

    public getErrors(isError: boolean = true): IError[] {
        return this.errors.filter(item => item.status === (isError ? statusFormActionEnum.Error : statusFormActionEnum.Success));
    }

    public getRequestData(): IHabitCategoryRequest {
        if (this.getErrors().length)
            throw new Error("Ошибка: некорректные данные");

        return {
            name: this.name as string,
            color: (this.color?.indexOf('#') === -1 ? ('#' + this.color) : this.color) as string,
            translations: this.translations,
            sort: this.sort,
            isActive: this.isActive,
        };
    }
}
