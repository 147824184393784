import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IDrawerState {
    openedLeft: boolean,
    openedRight: boolean,
}

const initialState: IDrawerState = {
    openedLeft: localStorage.getItem('drawer_main_left') === 'true',
    openedRight: false,
}

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setLeftDrawer(state, action: PayloadAction<boolean>) {
            const isOpen = action.payload;
            localStorage.setItem('drawer_main_left', isOpen ? 'true' : 'false');
            state.openedLeft = isOpen;
        },
        setRightDrawer(state, action: PayloadAction<boolean>) {
            state.openedRight = action.payload;
        }
    }
});

export const drawerActions = drawerSlice.actions;
export const drawerReducer = drawerSlice.reducer;
