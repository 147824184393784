import {ILanguage} from "../types/lang/ILanguage";
import {languageEnum} from "../enums/languageEnum";

export function getLanguages(): ILanguage[] {
    return [
        {
            name: 'Русский',
            code: languageEnum.Ru,
            image: "/assets/langs/" + languageEnum.Ru + ".png",
        },
        {
            name: 'English',
            code: languageEnum.En,
            image: "/assets/langs/" + languageEnum.En + ".png",
        }
    ];
}

export function findLanguage(code: string): ILanguage | undefined {
    return getLanguages().find(item => item.code === code);
}

export function getLocalLang() : ILanguage {
    return findLanguage(localStorage.getItem('lang') || '') || getLanguages().find(item => item.code === languageEnum.Ru) as ILanguage;
}
