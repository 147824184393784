import React, {useEffect, useRef, useState} from "react";
import './menu-bar.css';
import './modal-messages.css';

import {Button} from "primereact/button";
import {Avatar} from "primereact/avatar";
import {OverlayPanel} from 'primereact/overlaypanel';
import {OverlayElBell} from "./overlayElements/OverlayElBell";
import {useAppSelector} from "../../../../../../hooks/redux";
import {useActions} from "../../../../../../hooks/actions";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCog, faEnvelope, faMoon, faPowerOff, faSun, faUser} from "@fortawesome/free-solid-svg-icons";
import {getInitials} from "../../../../../../helpers/userHelper";
import {modeEnum} from "../../../../../../enums/modeEnum";
import {useTranslation} from "react-i18next";

export function MenuBarEnd() {
    const { t } = useTranslation();
    const {openedLeft} = useAppSelector(state => state.drawer);
    const [isVisibleMessage, setIsVisibleMessage] = useState(false);
    const {selectedModeStore} = useAppSelector(state => state.appSliceReducer);
    const {user} = useAppSelector(state => state.appAuthSliceReducer);
    const {setSelectedModeStore} = useActions().appActions;

    useEffect(() => {
    }, [isVisibleMessage]);

    const opMenu = useRef<OverlayPanel>(null);
    const opBell = useRef<OverlayPanel>(null);

    const {setAuthUser} = useActions().appAuthActions;

    const navigate = useNavigate();
    const onHandleLogout = () => {
        setAuthUser(null);
        navigate('/login');
    }

    return (
        <div className={'user-top-panel'}>
            <div
                className="item item-mode"
                onClick={() => setSelectedModeStore(selectedModeStore === 'dark' ? modeEnum.Light : modeEnum.Dark)}
            >
                <div className="mode-btn">
                    <FontAwesomeIcon
                        className={selectedModeStore + ' icon-mode'}
                        icon={selectedModeStore === 'dark' ? faSun : faMoon}
                    />
                </div>
            </div>
            <div className="item item-bell">
                <Button
                    onClick={e => opBell.current?.toggle(e)}
                    className="p-button-rounded" aria-label="Bookmark" badge="8">
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faBell}
                    />
                </Button>
            </div>
            <div className="item item-message">
                <Button
                    onClick={() => setIsVisibleMessage(!isVisibleMessage)}
                    className="p-button-rounded" aria-label="Search" badge="8">
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faEnvelope}
                    />
                </Button>
            </div>
            <div className="item item-user">
                <Button
                    onClick={e => opMenu.current?.toggle(e)}
                    className="p-button-rounded p-button-secondary" aria-label="Bookmark">
                    <Avatar label={!user?.avatar ? getInitials(user) : undefined} image={user?.avatar || undefined} size="normal"
                            shape="circle"/>
                </Button>
            </div>
            <OverlayPanel
                ref={opBell}
                appendTo={"self"}
                id="overlay_panel"
                style={{width: "450px", height: '450px'}}
                className="verlaypanel-notifications overlay-panel overlay-panel-bell"
            >
                <OverlayElBell/>
            </OverlayPanel>
            <OverlayPanel
                appendTo={'self'}
                ref={opMenu}
                showCloseIcon={false}
                id="overlay_panel"
                style={{fontSize: 12}}
                className="overlay-panel-user-menu-top overlay-panel"
            >

                <div className="items">
                    <div className="item-link">
                        <FontAwesomeIcon
                            width={20}
                            height={20}
                            icon={faUser}
                        />
                        {t('profile')}
                    </div>
                    <div className="item-link">
                        <FontAwesomeIcon
                            width={20}
                            height={20}
                            icon={faCog}
                        />
                        {t('setting')}
                    </div>
                    <div onClick={() => onHandleLogout()} className="item-link">
                        <FontAwesomeIcon
                            width={20}
                            height={20}
                            icon={faPowerOff}
                        />
                        {t('go_out')}
                    </div>
                </div>
            </OverlayPanel>
        </div>
    );
}
