import {IDefaultParams, IResponse} from "../../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../../makeServiceRequest";
import {IListTranslation, ITranslation} from "../../../types/lists/ITranslation";
import {IHabitPattern, IHabitPatternRequest} from "../../../types/lists/habit/IHabitPattern";

export async function serviceHabitPatternList(params?: IDefaultParams): Promise<IResponse<IHabitPattern[], null>> {
    const uri = '/admin/lists/habit-patterns';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IHabitPattern[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitPatternById(id: string): Promise<IResponse<IHabitPattern, null>> {
    const uri = '/admin/lists/habit-patterns/' + id;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IHabitPattern, null>(method, uri);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitPatternCreate(data: IHabitPatternRequest): Promise<IResponse<IHabitPattern, null>> {
    const uri = '/admin/lists/habit-patterns';
    const method: THttpMethod = 'post';

    try {
        return await makeServiceRequest<IHabitPattern, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitPatternUpdate(id: string, data: IHabitPatternRequest): Promise<IResponse<null, null>> {
    const uri = '/admin/lists/habit-patterns/' + id;
    const method: THttpMethod = 'put';

    try {
        return await makeServiceRequest<null, null>(method, uri, data);
    } catch (error) {
        throw error;
    }
}

export async function serviceHabitPatternDelete(id: string): Promise<IResponse<null, null>> {
    const uri = '/admin/lists/habit-patterns/' + id;
    const method: THttpMethod = 'delete';

    try {
        return await makeServiceRequest<null, null>(method, uri);
    } catch (error) {
        throw error;
    }
}
