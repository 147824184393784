import {ITranslation} from "../../../../types/lists/ITranslation";
import {GlobalSetting} from "./GlobalSetting";
import {AbstractBuilder} from "../../AbstractBuilder";
import {IError} from "../../../../types/IError";
import {ErrorHelper} from "../../../../helpers/ErrorHelper";

export class GlobalSettingBuilder extends AbstractBuilder {
    private setting!: GlobalSetting;

    constructor() {
        super();
        this.create();
    }

    protected create(): void {
        this.setting = new GlobalSetting();
    }

    public setKey(value: string | null): this {
        this.setting.key = value;

        return this;
    }

    public validateKey(isErrorUnique: boolean = false): IError {
        if (isErrorUnique)
            return ErrorHelper.getMessageError('Уникальный ключ', 'Такой уже существует', 'setting.key', true);

        return !this.setting.key?.length
            ? ErrorHelper.getMessageError('Уникальный ключ', 'Обязательно для заполнения', 'setting.key', true)
            : ErrorHelper.getMessageError('Уникальный ключ', 'Данные введены верно', 'setting.key');
    }

    public setOldKey(value: string): this {
        this.setting.oldKey = value;

        return this;
    }

    public validateOldKey(): IError {
        return !this.setting.oldKey?.length
            ? ErrorHelper.getMessageError('Уникальный старый ключ', 'Обязательно для заполнения', 'setting.oldKey', true)
            : ErrorHelper.getMessageError('Уникальный старый ключ', 'Данные введены верно', 'setting.oldKey');

    }

    public setName(value: string | null): this {
        this.setting.name = value;

        return this;
    }

    public setValue(value: string): this {
        this.setting.value = value;

        return this;
    }

    public setType(value: string | null) : this {
        this.setting.type = value;

        return this;
    }

    public validateType() : IError {
        return !this.setting.value?.length
            ? ErrorHelper.getMessageError('Тип данных', 'Обязательно для заполнения', 'setting.type', true)
            : ErrorHelper.getMessageError('Тип данных', 'Данные введены верно', 'setting.type');
    }

    public validateValue() : IError {
        return !this.setting.value?.length
            ? ErrorHelper.getMessageError('Основные данные', 'Обязательно для заполнения', 'setting.value', true)
            : ErrorHelper.getMessageError('Основные данные', 'Данные введены верно', 'setting.value');
    }

    public validateName(): IError {
        return !this.setting.name?.length
            ? ErrorHelper.getMessageError('Наименование', 'Обязательно для заполнения', 'setting.name', true)
            : ErrorHelper.getMessageError('Наименование', 'Данные введены верно', 'setting.name');
    }

    public setTranslation(value: ITranslation[]): this {
        this.setting.translations = value;

        return this;
    }

    public validateTranslation(): IError {
        this.setting.translations.map(item => {
            item.error = !item.name?.length
                ? ErrorHelper.getMessageError('Перевод', 'Вы не выбрали цвет', 'setting.translation.name', true)
                : ErrorHelper.getMessageError('Перевод', 'Данные выбраны верно', 'setting.translation.name');
        });

        return !this.setting.translations?.length
            ? ErrorHelper.getMessageError('Переводы', 'Обязательно для заполнения', 'setting.translation', true)
            : ErrorHelper.getMessageError('Переводы', 'Данные выбраны верно', 'setting.translation');
    }

    private validate(): void {
        this.setting.errors.push(this.validateValue());
        this.setting.errors.push(this.validateType());
        this.setting.errors.push(this.validateName());
        this.setting.errors.push(this.validateKey());
        this.setting.errors.push(this.validateOldKey());
        this.setting.errors.push(this.validateTranslation());
    }

    public get(): GlobalSetting {
        const setting = this.setting;
        this.validate();
        this.create();

        return setting;
    }
}
