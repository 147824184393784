import React, {FC, useState} from "react";
import '../style.css';

import {Col, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Splitter, SplitterPanel} from "primereact/splitter";
import {Editor} from "primereact/editor";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {EditorRenderHeader} from "../../../../components/ui/EditorRenderHeader";
import {TranslationEditor} from "../../../../components/business/translation/TranslationEditor";
import {ITranslation} from "../../../../types/lists/ITranslation";
import {ILanguage} from "../../../../types/lang/ILanguage";

interface IProps {
    value: string,
    translations: ITranslation[],
    languages: ILanguage[],
    handleValueBlur: (e: React.FocusEvent<HTMLDivElement>) => void,
    handleSyncTranslation: () => void,
    handleTranslationBlur: (e: React.FocusEvent<HTMLDivElement>, code: string) => void,
}

export const SectionHtml: FC<IProps> = (props): JSX.Element => {
    const {t} = useTranslation();

    const {value, languages, translations, handleValueBlur, handleSyncTranslation, handleTranslationBlur} = props;

    const [valueField, setValueField] = useState(value);

    return (
        <div className="content-type-html">
            <Row>
                <Col sm={12}>
                    <div className="splitter-content">
                        <div className="title">Основные данные</div>
                        <Splitter className={'splitter'}>
                            <SplitterPanel
                                className="flex align-items-center justify-content-center splitter-panel"
                            >
                                <Editor
                                    className={'editor'}
                                    value={valueField || ''}
                                    onTextChange={e => setValueField(e.htmlValue || '')}
                                    onBlur={handleValueBlur}
                                    headerTemplate={<EditorRenderHeader/>}
                                    style={{height: '300px'}}
                                />
                            </SplitterPanel>
                            <SplitterPanel
                                className="flex align-items-center justify-content-center splitter-panel"
                            >
                                <div className="result">
                                    {value && <div dangerouslySetInnerHTML={{__html: value}}/>}
                                </div>
                            </SplitterPanel>
                        </Splitter>
                    </div>
                </Col>
                <Col sm={12}>
                    <Panel
                        header={'Переводы *'}
                        toggleable
                        style={{marginTop: 25}}>
                        <Button
                            onClick={handleSyncTranslation}
                            disabled={!value?.length}
                            style={{marginTop: 15, marginBottom: 30}}
                            label={'Перевести автоматически'}
                            icon={<FontAwesomeIcon
                                width={30}
                                height={30}
                                size={'2x'}
                                icon={faLanguage}
                                style={{marginRight: 10}}
                            />}
                        />
                        {
                            translations.map(translation => {
                                const lang = languages.find(item => item.code === translation.code);

                                return lang ?
                                    <TranslationEditor
                                        key={lang.code}
                                        nameLang={lang.name}
                                        image={lang.image}
                                        code={lang.code}
                                        value={translation.name}
                                        isDisable={translation.isDisable}
                                        isProcessing={translation.isProcessing}
                                        onHandleBlur={handleTranslationBlur}
                                        error={translation.error}
                                    /> : ''
                            })
                        }
                    </Panel>
                </Col>
            </Row>
        </div>
    );
}
