import {IDefaultParams, IResponse} from "../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";
import {IUser} from "../../types/users/IUser";

export async function serviceUserList(params?: IDefaultParams): Promise<IResponse<IUser[], null>> {
    const uri = '/admin/users/app-users';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IUser[], null>(method, uri, null, params);
    } catch (error) {
        throw error;
    }
}

export async function serviceUserById(id: string): Promise<IResponse<IUser, null>> {
    const uri = '/admin/users/app-users/' + id;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IUser, null>(method, uri, null);
    } catch (error) {
        throw error;
    }
}

export async function serviceUserChangeActive(userId: string, active: boolean): Promise<IResponse<null, null>> {
    const uri = '/admin/users/app-users/active/' + userId + '?active=' + active;
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<null, null>(method, uri, null);
    } catch (error) {
        throw error;
    }
}
