import React, {FC, useState} from "react";
import '../style.css';

import {Col, Row} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {ITranslation} from "../../../../types/lists/ITranslation";
import {ILanguage} from "../../../../types/lang/ILanguage";
import {Textarea} from "../../../../components/ui/form/textarea/Textarea";
import {TranslationName} from "../../../../components/business/translation/TranslationName";

interface IProps {
    value: string,
    translations: ITranslation[],
    languages: ILanguage[],
    handleValueBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void,
    handleSyncTranslation: () => void,
    handleTranslationBlur: (e: React.ChangeEvent<HTMLInputElement>, code: string) => void,
    handleTranslationDescBlur: (e: React.FocusEvent<HTMLTextAreaElement>, code: string) => void,
}

export const SectionText: FC<IProps> = (props): JSX.Element => {
    const {t} = useTranslation();

    const {
        value,
        languages,
        translations,
        handleValueBlur,
        handleSyncTranslation,
        handleTranslationBlur,
        handleTranslationDescBlur,
    } = props;

    const [valueField, setValueField] = useState(value);

    return (
        <div className="content-type-html">
            <Row>
                <Col sm={12}>
                    <div className="splitter-content">
                        <div className="title">Основные данные</div>
                        <Textarea
                            title={'Основные данные'}
                            value={valueField}
                            onHandleBlur={handleValueBlur}
                            onHandleChange={e => setValueField(e.target.value)}
                        />
                    </div>
                </Col>
                <Col sm={12}>
                    <Panel
                        header={'Переводы *'}
                        toggleable
                        style={{marginTop: 25}}>
                        <Button
                            onClick={handleSyncTranslation}
                            disabled={!value?.length}
                            style={{marginTop: 15, marginBottom: 30}}
                            label={'Перевести автоматически'}
                            icon={<FontAwesomeIcon
                                width={30}
                                height={30}
                                size={'2x'}
                                icon={faLanguage}
                                style={{marginRight: 10}}
                            />}
                        />
                        {
                            translations.map(translation => {
                                const lang = languages.find(item => item.code === translation.code);

                                return lang ?
                                    <TranslationName
                                        key={lang.code}
                                        nameLang={lang.name}
                                        image={lang.image}
                                        code={lang.code}
                                        value={translation.name}
                                        valueDescription={translation.description || ''}
                                        isDisable={translation.isDisable}
                                        isProcessing={translation.isProcessing}
                                        onHandleBlur={handleTranslationBlur}
                                        onHandleDescBlur={handleTranslationDescBlur}
                                        error={translation.error}
                                        errorDesc={translation.errorDesc}
                                    /> : ''
                            })
                        }
                    </Panel>
                </Col>
            </Row>
        </div>
    );
}
