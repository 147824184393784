import {statusFormActionEnum} from "../enums/statusFormActionEnum";
import {IError} from "../types/IError";

export class ErrorHelper {
    public static getMessageError(text: string, message: string, key: string, isError: boolean = false): IError {
        return {
            name: text,
            status: isError ? statusFormActionEnum.Error : statusFormActionEnum.Success,
            message: message,
            key: key
        };
    }
}
