import React, {useEffect, useRef, useState} from "react";
import '../style.css';

import {Container, Row, Col} from 'react-grid-system';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome, faLanguage} from "@fortawesome/free-solid-svg-icons";
import {routesEnum} from "../../../../enums/routesEnum";

import {IError} from "../../../../types/IError";
import {Toast} from "primereact/toast";
import {actionBtn, FormSplitButton} from "../../../../components/ui/buttons/FormSplitButton";
import {useTranslation} from "react-i18next";
import {LottieLoadingSuccessfully} from "../../../../components/ui/animations/LottieLoadingSuccessfully";
import {Breadcrumb} from "../../../../components/ui/breadcrumb/Breadcrumb";
import {Splitter, SplitterPanel} from "primereact/splitter";
import {Editor} from "primereact/editor";
import {
    serviceGlobalAppSettingById,
    serviceGlobalAppSettingCreate,
    serviceGlobalAppSettingList,
    serviceGlobalAppSettingUpdate
} from "../../../../services/setting/GlobalAppSettingService";
import {IGlobalSetting} from "../../../../types/setting/IGlobalSetting";
import {EditorRenderHeader} from "../../../../components/ui/EditorRenderHeader";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {ITranslation} from "../../../../types/lists/ITranslation";
import {ILanguage} from "../../../../types/lang/ILanguage";
import {
    initializeLanguagesAndTranslations,
    syncAllTranslations,
    updateTranslation, updateTranslationOnBlur
} from "../../../../utils/translationsUtils";
import {TranslationEditor} from "../../../../components/business/translation/TranslationEditor";
import {GlobalSettingBuilder} from "../../../../classes/business/setting/global/GlobalSettingBuilder";
import {Input} from "../../../../components/ui/form/input/Input";
import {Dropdown} from "../../../../components/ui/form/dropdown/Dropdown";
import {SectionHtml} from "../sections/SectionHtml";
import {SectionNumber} from "../sections/SectionNumber";
import {SectionText} from "../sections/SectionText";

const settingBuilder = new GlobalSettingBuilder();
const breadcrumbs = (mainLabel: string, settingLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: settingLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, route: routesEnum.List_habit_category_list
    },
];

export function GlobalAppSettingCreate() {
    const {t} = useTranslation();

    const [name, setName] = useState<string>('');

    const [keys, setKeys] = useState<string[]>([]);
    const [key, setKey] = useState<string>('');
    const [keyError, setKeyError] = useState<IError | null>(null);
    const [oldKey, setOldKey] = useState<string>('');

    const [types, setTypes] = useState<{name: string}[]>([]);
    const [selectedType, setSelectedType] = useState<{name: string} | null>(null);

    const [value, setValue] = useState<string>('');
    const [valueError, setValueError] = useState<IError | null>(null);
    const [valueData, setValueData] = useState<IGlobalSetting | null>(null);
    const [translations, setTranslations] = useState<ITranslation[]>([]);
    const [, setTranslationError] = useState<IError | null>(null);
    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [nameCodeLang, setNameCodeLang] = useState<string>('');

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isCompletion, setIsCompletion] = useState<boolean>(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const toast = useRef<Toast>(null);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        setIsLoadingData(true);

        setTypes([
            {
                name: 'html'
            },
            {
                name: 'number'
            },
            {
                name: 'text'
            },
        ]);

        serviceGlobalAppSettingList().then(response => {
            const data = response?.data || [];
            const uniqueItemsMap = new Map<string, {key: string, type: string}>();

            data.forEach(item => {
                if (!uniqueItemsMap.has(item.key)) {
                    uniqueItemsMap.set(item.key, item);
                }
            });

            setKeys(data.map(item => item.key));

            setIsLoadingData(false);

        }).catch(() => {
            setIsLoadingData(false);
        });
    }, [isInitialLoad]);

    useEffect(() => {
        initializeLanguagesAndTranslations(setLanguages, setTranslations);
    }, []);

    const onHandleClickCreate = (e: React.MouseEvent<HTMLElement>, action: actionBtn): void => {
        settingBuilder
            .setName(name)
            .setValue(value)
            .setType(selectedType?.name || null)
            .setKey(key)
            .setOldKey(oldKey || key)
            .setTranslation(translations);

        setValueError(settingBuilder.validateName());
        setTranslationError(settingBuilder.validateTranslation());

        const setting = settingBuilder.get();
        setTranslations(setting.translations);

        if (setting.getErrors().length)
            return;

        setIsLoadingCreate(true);
        serviceGlobalAppSettingCreate(setting.getRequestData()).then(response => {
            setIsLoadingCreate(false);
            if (!response.success) {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Создание',
                    detail: 'Данные не были созданы',
                    life: 3000,
                });

                return;
            }

            setOldKey(key);
            setKeyError(null);
            setKey('');
            setName('');
            setValue('');
            setSelectedType(null);

            const updatedTranslations = translations.map(item => {
                return {...item, name: '', description: '', error: null, errorDesc: null, isDisable: false, isProcessing: false};
            });

            setTranslations(updatedTranslations);

            setTimeout(() => {
                setIsCompletion(false);
            }, 3000);

            toast.current?.show({
                severity: 'success',
                summary: 'Создание',
                detail: 'Данные успешно созданы',
                life: 3000,
            });

            return;
        });
    }

    const onHandleValueBlur = async (e: React.FocusEvent<HTMLDivElement>) => {
        await updateTranslationOnBlur(e.target.innerHTML, setValue, setNameCodeLang, setValueError, translations, setTranslations);
    }

    const onHandleValueTextBlur = async (e: React.FocusEvent<HTMLTextAreaElement>) => {
        await updateTranslationOnBlur(e.target.innerHTML, setValue, setNameCodeLang, setValueError, translations, setTranslations);
    }

    const onHandleSyncTranslation = async () => {
        await syncAllTranslations(name, value, nameCodeLang, null, translations, setTranslations);
    }

    const onHandleTranslationBlur = (e: React.FocusEvent<HTMLDivElement>, code: string) => {
        updateTranslation(e.target.innerHTML, code, translations, setTranslations);
    }

    const onHandleTranslationTextBlur = (e: React.ChangeEvent<HTMLInputElement>, code: string) => {
        updateTranslation(e.target.value, code, translations, setTranslations);
    }

    const onHandleTranslationTextDescBlur = (e: React.FocusEvent<HTMLTextAreaElement>, code: string) => {
        updateTranslation(e.target.value, code, translations, setTranslations, true);
    }

    const onHandleKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputKey = e.target.value;
        if (inputKey === oldKey) {
            setKeyError(null);

            return;
        }

        serviceGlobalAppSettingById(inputKey).then(response => {
            setKeyError(response.data?.key ? settingBuilder.validateKey(true) : null);
        });
    }

    const onHandleValueTranslationBlur = (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, isDesc: boolean = false): void => {
        updateTranslationOnBlur(e.target.value, setValue, setNameCodeLang, setValueError, translations, setTranslations, true);
    }

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb
                items={breadcrumbs(t('label_main'), t('label_global_setting'))}/>
            <Toast appendTo={null} ref={toast} style={{zIndex: 1000}} className={'toast'}/>
            <div className="content-page">
                {
                    isLoadingCreate || isCompletion ?
                        <div style={{paddingTop: 25}}>
                            <LottieLoadingSuccessfully
                                isUpdate={isLoadingCreate}
                                isCompletion={isCompletion}
                            />
                        </div>
                        : ''
                }
                {
                    !isLoadingCreate && !isCompletion ?
                        <div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Dropdown
                                            width={'100%'}
                                            title={'Тип данных'}
                                            value={selectedType}
                                            options={types}
                                            dataKey={'name'}
                                            onHandleChange={e => setSelectedType(e.value)}
                                            placeholder={'Выберите тип данных'}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Input
                                            disabled={!selectedType}
                                            maxlength={255}
                                            value={key}
                                            title={'Уникальное наименование *'}
                                            onHandleChange={e => setKey(e.target.value)}
                                            onHandleBlur={onHandleKey}
                                            error={keyError}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <Input
                                            disabled={!selectedType}
                                            maxlength={255}
                                            value={name}
                                            title={'Наименование'}
                                            onHandleBlur={onHandleValueTranslationBlur}
                                            onHandleChange={e => setName(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                {
                                    selectedType?.name === 'html' ?
                                        <SectionHtml
                                            value={value}
                                            translations={translations}
                                            languages={languages}
                                            handleValueBlur={onHandleValueBlur}
                                            handleSyncTranslation={onHandleSyncTranslation}
                                            handleTranslationBlur={onHandleTranslationBlur}
                                        />
                                        : ''
                                }

                                {
                                    selectedType?.name === 'number' ?
                                        <SectionNumber
                                            value={Number(value)}
                                            handleValueBlur={onHandleValueBlur}
                                        />
                                        : ''
                                }

                                {
                                    selectedType?.name === 'text' ?
                                        <SectionText
                                            value={value}
                                            translations={translations}
                                            languages={languages}
                                            handleValueBlur={onHandleValueTextBlur}
                                            handleSyncTranslation={onHandleSyncTranslation}
                                            handleTranslationBlur={onHandleTranslationTextBlur}
                                            handleTranslationDescBlur={onHandleTranslationTextDescBlur}
                                        />
                                        : ''
                                }

                                {/*<div className="content-type-html">*/}
                                {/*    <Row>*/}
                                {/*        <Col sm={12}>*/}
                                {/*            <div className="splitter-content">*/}
                                {/*                <div className="title">Основные данные</div>*/}
                                {/*                <Splitter className={'splitter'}>*/}
                                {/*                    <SplitterPanel*/}
                                {/*                        className="flex align-items-center justify-content-center splitter-panel"*/}
                                {/*                    >*/}
                                {/*                        <Editor*/}
                                {/*                            className={'editor'}*/}
                                {/*                            value={value || ''}*/}
                                {/*                            onTextChange={e => setValue(e.htmlValue || '')}*/}
                                {/*                            onBlur={onHandleValueBlur}*/}
                                {/*                            headerTemplate={<EditorRenderHeader/>}*/}
                                {/*                            style={{height: '300px'}}*/}
                                {/*                        />*/}
                                {/*                    </SplitterPanel>*/}
                                {/*                    <SplitterPanel*/}
                                {/*                        className="flex align-items-center justify-content-center splitter-panel"*/}
                                {/*                    >*/}
                                {/*                        <div className="result">*/}
                                {/*                            {value && <div dangerouslySetInnerHTML={{__html: value}}/>}*/}
                                {/*                        </div>*/}
                                {/*                    </SplitterPanel>*/}
                                {/*                </Splitter>*/}
                                {/*            </div>*/}
                                {/*        </Col>*/}
                                {/*        <Col sm={12}>*/}
                                {/*            <Panel*/}
                                {/*                header={'Переводы *'}*/}
                                {/*                toggleable*/}
                                {/*                style={{marginTop: 25}}>*/}
                                {/*                <Button*/}
                                {/*                    onClick={onHandleSyncTranslation}*/}
                                {/*                    disabled={!value?.length}*/}
                                {/*                    style={{marginTop: 15, marginBottom: 30}}*/}
                                {/*                    label={'Перевести автоматически'}*/}
                                {/*                    icon={<FontAwesomeIcon*/}
                                {/*                        width={30}*/}
                                {/*                        height={30}*/}
                                {/*                        size={'2x'}*/}
                                {/*                        icon={faLanguage}*/}
                                {/*                        style={{marginRight: 10}}*/}
                                {/*                    />}*/}
                                {/*                />*/}
                                {/*                {*/}
                                {/*                    translations.map(translation => {*/}
                                {/*                        const lang = languages.find(item => item.code === translation.code);*/}

                                {/*                        return lang ?*/}
                                {/*                            <TranslationEditor*/}
                                {/*                                key={lang.code}*/}
                                {/*                                nameLang={lang.name}*/}
                                {/*                                image={lang.image}*/}
                                {/*                                code={lang.code}*/}
                                {/*                                value={translation.name}*/}
                                {/*                                isDisable={translation.isDisable}*/}
                                {/*                                isProcessing={translation.isProcessing}*/}
                                {/*                                onHandleBlur={onHandleTranslationBlur}*/}
                                {/*                                error={translation.error}*/}
                                {/*                            /> : ''*/}
                                {/*                    })*/}
                                {/*                }*/}
                                {/*            </Panel>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</div>*/}
                            </Container>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-btn">
                                            <FormSplitButton
                                                isEdit={true}
                                                isList={false}
                                                onHandleBtn={onHandleClickCreate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : ''
                }
            </div>
        </div>
    );
}
