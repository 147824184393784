import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {
    ITrusted, ITrustedRequest,
} from "../../../../types/users/ITrustedUser";

export class TrustedUser {
    public users!: ITrusted[];
    public password!: string | null;
    public rePassword!: string | null;
    public code!: string | null;
    public isActive: boolean = true;
    public isAuthAdminPanel: boolean = false;
    public errors: IError[] = [];

    public getErrors(isError: boolean = true): IError[] {
        return this.errors.filter(item => item.status === (isError ? statusFormActionEnum.Error : statusFormActionEnum.Success));
    }

    public getRequestData(): ITrustedRequest {
        if (this.getErrors().length)
            throw new Error("Ошибка: некорректные данные");

        return {
            users: this.users.map(item => item.id),
            password: this.password?.length ? this.password : null,
            rePassword: this.rePassword?.length ? this.rePassword : null,
            code: this.code as string,
            isActive: this.isActive,
            isAuthAdminPanel: this.isAuthAdminPanel,
        };
    }
}
