export enum routesEnum {
    Login = '/login',
    Home = '/',
    Settings = '/settings',
    GlobalSettingList = '/global-app-settings',
    GlobalSettingCreate = '/global-app-settings/create',
    GlobalSettingEditWithId = '/global-app-settings/edit/:id',
    GlobalSettingEdit = '/global-app-settings/edit',

    GlobalSettingAboutUs = '/global-app-setting/about-us',
    GlobalSettingTermsUse = '/global-app-setting/terms-use',

    List_habit_category_list = '/lists/habits/categories',
    List_habit_category_create = '/lists/habits/categories/create',
    List_habit_category_edit_with_id = '/lists/habits/categories/edit/:id',
    List_habit_category_edit_edit = '/lists/habits/categories/edit',

    List_habit_icon_category_list = '/lists/habits/categories/icons',
    List_habit_icon_category_create = '/lists/habits/categories/icons/create',
    List_habit_icon_category_edit_with_id = '/lists/habits/categories/icons/edit/:id',
    List_habit_icon_category_edit_edit = '/lists/habits/categories/icons/edit',

    List_habit_pattern_list = '/lists/habits/patterns',
    List_habit_pattern_create = '/lists/habits/patterns/create',
    List_habit_pattern_edit_with_id = '/lists/habits/patterns/edit/:id',
    List_habit_pattern_edit_edit = '/lists/habits/patterns/edit',

    Trusted_user_list = '/users/trusted-users',
    Trusted_user_create = '/users/trusted-users/create',
    Trusted_user_edit_with_id = '/users/trusted-users/edit/:id',
    Trusted_user_edit_edit = '/users/trusted-users/edit',

    User_list = '/app-users',
    User_create = '/app-users/create',
    User_edit_with_id = '/app-users/edit/:id',
    User_edit_edit = '/app-users/edit',

    Developer_habit = "/developer/habits"
}
