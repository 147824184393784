import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getCookie, setCookie } from 'typescript-cookie'
import moment from "moment";
import {IAuthInfoToken} from "../../../types/auth/IAuth";

interface IAppAuthSliceState {
    token: string,
    user: any | null,
    isAuth: boolean,
    info: IAuthInfoToken | null,
}

const initialState: IAppAuthSliceState = {
    token: '',
    user: geLocalStoreUser(),
    isAuth: false,
    info: null,
}

function geLocalStoreUser() {
    let user = getCookie('auth_user');

    return user?.length ? JSON.parse(user) : null;
}

export const appAuthSlice = createSlice({
    name: 'authApplication',
    initialState,
    reducers: {
        setAuthUser(state, action) {
            setCookie('auth_user', JSON.stringify(action.payload));
            state.token = action.payload?.token || '';
            state.user = action.payload?.user || null;
            state.info = action.payload?.info || null;
        },
        delAuthUser(state) {
            setCookie('auth_user', JSON.stringify(null));
            state.token = '';
            state.user = null;
        },
        checkAuthUser(state) {
            let data: any = null;

            data = getCookie('auth_user');
            if (data) {
                data = JSON.parse(data);
                state.user = data?.user || null;
                state.token = data?.token || null;
                state.info = data?.info || null;
            }

            state.isAuth = checkTimeToken(data?.info);
        }
    }
});

function checkTimeToken(info: IAuthInfoToken | null) : boolean {
    if (!info?.expiresAt)
        return false;

    const expiresInSec = moment(info?.expiresAt).unix();
    const timeSecNow = moment().subtract('00:00"10').unix();

    return expiresInSec > timeSecNow;
}

export const appAuthSliceActions = appAuthSlice.actions;
export const appAuthSliceReducer = appAuthSlice.reducer;
