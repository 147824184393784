import React, {FC, ReactElement} from "react";
import {Link, useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import {IDrawerMenuSubItem} from "../../../../types/drawerMenu/IDrawerMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";

interface itemsProps {
    onHandleSubMenuActive: (id: string, isActive: boolean) => void,
    id: string,
    name: string,
    disable?: boolean | null,
    link?: string,
    icon: ReactElement,
    subMenuActive: boolean,
    children: IDrawerMenuSubItem[] | undefined,
}

const subVariants = {
    open: {opacity: 1, x: 0, height: '100%'},
    closed: {opacity: 0, x: "-100%", height: '0',},
}

function isActiveItem(items: IDrawerMenuSubItem[], uri: string) : boolean {
   return !!items?.find((item: IDrawerMenuSubItem) => item.link === uri);
}

export const DrawerMenuItem: FC<itemsProps> = (props): ReactElement => {
    const {name, icon, link, subMenuActive, children, id, onHandleSubMenuActive} = props;
    const location = useLocation();
    return (
        <>
            {
                link !== undefined && link ?
                    <Link
                        onClick={() => onHandleSubMenuActive(id, subMenuActive)}
                        className={"list-group-item" + (!link ? (isActiveItem(children || [], location.pathname) ? ' active' : '') : (link === location.pathname ? ' active' : ''))}
                        to={link || ''}>
                        {icon}
                        <span>{name}</span>
                        {
                            children?.length ?
                                <div className={'icon-children'}>
                                    <FontAwesomeIcon
                                        width={20}
                                        height={20}
                                        icon={subMenuActive ? faChevronUp : faChevronDown}
                                    />
                                </div> : ''
                        }
                    </Link>
                    :
                    <li
                        onClick={() => onHandleSubMenuActive(id, subMenuActive)}
                        className={"list-group-item" + (!link ? (isActiveItem(children || [], location.pathname) ? ' active' : '') : (link === location.pathname ? ' active' : ''))}>
                        {
                            link !== undefined && link ?
                                <Link
                                    className={location.pathname === link ? 'active' : ''}
                                    to={link}>
                                    {icon}
                                    <span>{name}</span>
                                </Link>
                                :
                                <>
                                    {icon}
                                    <span>{name}</span>
                                </>
                        }
                        {
                            children?.length ?
                                <div className={'icon-children'}>
                                    <FontAwesomeIcon
                                        width={20}
                                        height={20}
                                        icon={subMenuActive ? faChevronUp : faChevronDown}
                                    />
                                </div> : ''
                        }
                    </li>
            }
            {
                children ?
                    <motion.div animate={subMenuActive ? "open" : "closed"}
                                variants={subVariants}>
                        <ul className={"br-menu-sub nav " + (subMenuActive ? "open" : '')}>
                            <li className="nav-item">
                                {
                                    children?.map((item, index) => {
                                        return (
                                            <Link
                                                className={location.pathname === item.link ? 'active' : ''}
                                                key={index}
                                                to={item.link}>
                                                {item.name}
                                            </Link>
                                        )
                                    })
                                }
                            </li>
                        </ul>
                    </motion.div>
                    : ''
            }
        </>
    );
}
