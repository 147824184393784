import {ITranslation} from "../../../../types/lists/ITranslation";
import {AbstractBuilder} from "../../AbstractBuilder";
import {IError} from "../../../../types/IError";
import {statusFormActionEnum} from "../../../../enums/statusFormActionEnum";
import {validateNumber} from "../../../../helpers/validateFormatHelper";
import {CategoryIcon} from "./CategoryIcon";
import {IHabitCategory} from "../../../../types/lists/habit/IHabitCategory";
import {ErrorHelper} from "../../../../helpers/ErrorHelper";

export class CategoryIconBuilder extends AbstractBuilder {
    private categoryIcon!: CategoryIcon;

    constructor() {
        super();
        this.create();
    }

    protected create(): void {
        this.categoryIcon = new CategoryIcon();
    }

    public setName(value: string | null): this {
        this.categoryIcon.name = value;

        return this;
    }

    public validateName(): IError {
        return !this.categoryIcon.name?.length
            ? ErrorHelper.getMessageError('Наименование', 'Обязательно для заполнения', 'category.name', true)
            : ErrorHelper.getMessageError('Наименование', 'Данные введены верно', 'category.name');
    }

    public setIcon(value: File | string | null): this {
        this.categoryIcon.icon = value;

        return this;
    }

    public validateIcon(): IError {
        const icon = this.categoryIcon.icon;
        if (typeof this.categoryIcon.icon === 'string')
            return !this.categoryIcon.icon?.length
                ? ErrorHelper.getMessageError('Изображение', 'Изображение не было загружено', 'category.icon', true)
                : ErrorHelper.getMessageError('Изображение', 'Изображение соответствует требованию', 'category.icon');

        return icon instanceof File
            ? ErrorHelper.getMessageError('Изображение', 'Изображение соответствует требованию', 'category.icon')
            : ErrorHelper.getMessageError('Изображение', 'Изображение не было загружено', 'category.icon', true);
    }

    public setIconPdf(value: File | null) : this {
        this.categoryIcon.iconPdf = value;

        return this;
    }

    public setIconPng(value: File | null) : this {
        this.categoryIcon.iconPng = value;

        return this;
    }

    public setCategory(value: IHabitCategory | null): this {
        this.categoryIcon.category = value;

        return this;
    }

    public validateCategory(): IError {
        return !this.categoryIcon.category?.id
            ? ErrorHelper.getMessageError('Категория привычки', 'Категория не была выбрана', 'category.category', true)
            : ErrorHelper.getMessageError('Категория привычки', 'Данные выбраны верно', 'category.category');
    }

    public setTranslation(value: ITranslation[]): this {
        this.categoryIcon.translations = value;

        return this;
    }

    public validateTranslation(): IError {
        this.categoryIcon.translations.map(item => {
            item.error = !item.name?.length
                ? ErrorHelper.getMessageError('Перевод', 'Поле обязательно для заполнение', 'category.translation.name', true)
                : ErrorHelper.getMessageError('Перевод', 'Данные заполнены верно', 'category.translation.name');
        });

        return !this.categoryIcon.translations?.length
            ? ErrorHelper.getMessageError('Переводы', 'Поле обязательно для заполнение', 'category.translation', true)
            : ErrorHelper.getMessageError('Переводы', 'Данные заполнены верно', 'category.translation');
    }

    public setIsActive(value: boolean): this {
        this.categoryIcon.isActive = value;

        return this;
    }

    public setIsNegative(value: boolean): this {
        this.categoryIcon.isNegative = value;

        return this;
    }

    public setSort(value: string | number): this {
        this.categoryIcon.sort = validateNumber(value);

        return this;
    }

    private validate(): void {
        this.categoryIcon.errors.push(this.validateName());
        this.categoryIcon.errors.push(this.validateIcon());
        this.categoryIcon.errors.push(this.validateCategory());
        this.categoryIcon.errors.push(this.validateTranslation());
        // this.categoryIcon.errors.push(this.validateIconPdf());
    }

    public get(): CategoryIcon {
        const category = this.categoryIcon;
        this.validate();
        this.create();

        return category;
    }
}
