import React from "react";
import './overlay-el-bells.css';
import {faBellSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function OverlayElBell() {
    return (
        <div className={'notification'}>
            <div className="notification-empty">
                <div>
                    <FontAwesomeIcon
                        width={20}
                        height={20}
                        icon={faBellSlash}
                    />
                </div>
            </div>
        </div>
    );
}
