import React, {JSX, useEffect, useRef, useState} from "react";
import '../../../styles/list.css';
import './trusted-user.css';
import '../../../../components/ui/table/table.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxesStacked, faHome, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Skeleton} from "primereact/skeleton";
import {Tag} from "primereact/tag";
import {Paginator, PaginatorPageChangeEvent} from "primereact/paginator";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Toast} from 'primereact/toast';
import {Toolbar} from "primereact/toolbar";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {
    serviceHabitCategoryDelete,
    serviceHabitCategoryList
} from "../../../../services/lists/habit/habitCategoryService";
import {routesEnum} from "../../../../enums/routesEnum";
import {Control} from "../../../../components/ui/table/control/Control";
import {Breadcrumb} from "../../../../components/ui/breadcrumb/Breadcrumb";
import {TableEmpty} from "../../../../components/ui/table/TableEmpty";
import {loadingEmptyTableItemsHelper} from "../../../../helpers/loadingEmptyTableItemsHelper";
import {IHabitCategory} from "../../../../types/lists/habit/IHabitCategory";
import {ToggleButton} from "primereact/togglebutton";
import moment from "moment";
import {serviceTrustedUserDelete, serviceTrustedUserList} from "../../../../services/users/trustedUserService";
import {ITrustedUser} from "../../../../types/users/ITrustedUser";

const breadcrumbs = (mainLabel: string, habitCategoryLabel: string) => [
    {
        label: mainLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faHome}
        />, route: routesEnum.Home
    },
    {
        label: habitCategoryLabel, icon: <FontAwesomeIcon
            className={'icon'}
            width={20}
            height={20}
            icon={faBoxesStacked}
        />, disable: true
    },
];

export function TrustedUserList() {
    const {t} = useTranslation();
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [page, setPage] = useState<number>(0);

    const [isBtnEditLoading, setIsBtnEditLoading] = useState<boolean>(false);

    const [items, setItems] = useState<ITrustedUser[]>([]);
    const [helperItemId, setHelperItemId] = useState<string | null>(null);
    const [isLoadingItems, setIsLoadingItems] = useState(true);

    const toast = useRef<Toast>(null);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        setIsLoadingItems(true);

        serviceTrustedUserList({limit, page: page + 1}).then(response => {
            setIsLoadingItems(false);

            setItems(response.data || []);
            setTotal(response.pagination?.total || 0);
        });

    }, [isInitialLoad, limit, page]);

    const onPageChange = (event: PaginatorPageChangeEvent): void => {
        setPage(event.first / event.rows);
        setPage(event.first / event.rows);

        setLimit(event.rows);
    }

    const onHandleClickDelete = (id: string): void => {
        setHelperItemId(id);

        const item = items.find(item => item.id === id);
        if (item) {
            confirmDialog({
                message: <span>Вы точно хотите удалить из доверенных пользователей <b><i>"{item.name || item.phone}"</i></b>?</span>,
                header: 'Запрос на удаление',
                icon: 'pi pi-exclamation-triangle',
                accept: (): void => {
                    setIsBtnEditLoading(true);
                    serviceTrustedUserDelete(id).then(response => {
                        setIsBtnEditLoading(false);

                        toast.current?.show({
                            severity: 'success',
                            summary: 'Успешное удаление',
                            detail: 'Вы успешно удалили доверенного пользователя',
                            life: 3000
                        });

                        setItems(items.filter(valueItem => valueItem.id !== item.id));
                        setHelperItemId(null);
                    });
                },
                reject: (): void => {
                    setHelperItemId(null);
                    toast.current?.show({
                        severity: 'warn',
                        summary: 'Отмена удаление',
                        detail: 'Вы отменили действие',
                        life: 3000
                    });
                },
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
            });
        }
    }

    const tableControl = (item: ITrustedUser): JSX.Element => {
        return <Control
            onHandleClickDelete={onHandleClickDelete}
            isLoading={isLoadingItems}
            isBtnLoading={isBtnEditLoading}
            itemId={item.id}
            helperItemId={helperItemId}
            btnEditUrl={routesEnum.Trusted_user_edit_edit + `/${item.id}`}
        />
    }

    const idTableBodyTemplate = (item: ITrustedUser) => {
        return isLoadingItems ? <Skeleton width="2rem" height="20px"/> : <Tag className={'tag-id'} value={item.id}/>;
    }

    const nameTableBodyTemplate = (item: ITrustedUser): JSX.Element => {
        return isLoadingItems ? <Skeleton width="15rem" height="15px"/>
            : <span>{item.name || '-'}</span>
    }

    const phoneTableBodyTemplate = (item: ITrustedUser): JSX.Element => {
        return isLoadingItems ? <Skeleton width="5rem" height="15px"/>
            : <span>{item.phone}</span>
    }

    const codeTableBodyTemplate = (item: ITrustedUser): JSX.Element => {
        return isLoadingItems ? <Skeleton width="5rem" height="15px"/>
            : <Tag value={item.code} />
    }

    const activeTableBodyTemplate = (item: IHabitCategory): JSX.Element => {
        return isLoadingItems ? <Skeleton width="5rem" height="15px"/>
            : <ToggleButton onLabel="Да" offLabel="Нет" onIcon="pi pi-check" offIcon="pi pi-times"
                            checked={item.isActive} disabled={true} className="w-9rem"/>
    }

    const createTableBodyTemplate = (item: IHabitCategory): JSX.Element => {
        return isLoadingItems ? <Skeleton width="5rem" height="15px"/>
            : <span>{item.createdAt ? moment(item.createdAt).format('DD.MM.Y hh:mm') : '-'}</span>
    }

    const updateTableBodyTemplate = (item: IHabitCategory): JSX.Element => {
        return isLoadingItems ? <Skeleton width="5rem" height="15px"/>
            : <span>{item.updatedAt ? moment(item.updatedAt).format('DD.MM.Y hh:mm') : '-'}</span>
    }

    const toolbarStartContent = (
        <div className={'panel-content-left'}>
            <Link
                to={routesEnum.Trusted_user_create}>
                <Button label={t('label_habit_category_creating')} icon={
                    <FontAwesomeIcon
                        className={'icon'}
                        width={20}
                        height={20}
                        icon={faPlus}
                    />
                } className="mr-2"/>
            </Link>
        </div>
    );

    const toolbarEndContent = (
        <div className={'panel-content-right'}>
            <Button icon={
                <FontAwesomeIcon
                    className={'icon'}
                    width={20}
                    height={20}
                    icon={faTrash}
                />
            } className="p-button-danger"/>
        </div>
    );

    return (
        <div className={'section-content section-page-list'}>
            <Breadcrumb items={breadcrumbs(t('label_main'), t('label_habit_category'))}/>
            <Toast ref={toast}/>
            <ConfirmDialog/>
            <div className="content-page">
                <div
                    className={'table-items'}>
                    <div className="toolbar">
                        <Toolbar
                            className={'toolbar-panel'}
                            start={toolbarStartContent}
                            end={toolbarEndContent}/>
                    </div>
                    <DataTable
                        emptyMessage={!isLoadingItems ? <TableEmpty/> : undefined}
                        value={isLoadingItems ? loadingEmptyTableItemsHelper(limit) : items}
                        className={'table ' + (!items.length ? 'empty' : '')}
                    >
                        <Column field="id" header="ID" body={idTableBodyTemplate}/>
                        <Column field="name" header="Имя" body={nameTableBodyTemplate}/>
                        <Column field="phone" align={'center'} style={{width: 60}} header="Номер телефона"
                                body={phoneTableBodyTemplate}/>
                        <Column field="code" align={'center'} header="Код" body={codeTableBodyTemplate}/>
                        <Column field="active" align={'center'} header="Активность" body={activeTableBodyTemplate}/>
                        <Column field="createAt" align={'center'} header="Дата создания"
                                body={createTableBodyTemplate}/>
                        <Column field="updateAt" align={'center'} header="Дата обновления"
                                body={updateTableBodyTemplate}/>
                        <Column field="control" header="" body={tableControl}/>
                    </DataTable>
                    <div className="paginator-content">
                        {
                            isLoadingItems ? <div className="mask-paginator-disable"/> : ''
                        }
                        <Paginator
                            first={page * limit}
                            rows={limit}
                            totalRecords={total}
                            rowsPerPageOptions={[5, 25, 30]}
                            onPageChange={onPageChange}
                            className={'disabled-paginator paginator'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
