import React, {FC, JSX, useRef, useState} from "react";
import './upload-image.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {Button} from "primereact/button";

interface itemsProps {
    onImageUpload: (image: File | null) => void;
    value: File | string | null,
    title?: string
}

export const UploadImage: FC<itemsProps> = (props): JSX.Element => {
    const {onImageUpload, value, title} = props;

    const [dragging, setDragging] = useState(false);
    const [previewImage, setPreviewImage] = useState<File | string | null>(value);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragging(false);

        const files = e.dataTransfer.files;

        if (files.length > 0) {
            const imageFile = files[0];
            const imageUrl = URL.createObjectURL(imageFile);
            setPreviewImage(imageUrl);
            onImageUpload(imageFile);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const imageFile = files[0];
            const imageUrl = URL.createObjectURL(imageFile);
            setPreviewImage(imageUrl);
            onImageUpload(imageFile);
        }
    };

    return (
        <div className="upload-image">
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <div
                className={`upload-content ${dragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                {previewImage ? (
                    <div className="preview-image">
                        <img src={previewImage instanceof File ? URL.createObjectURL(previewImage) : previewImage} alt="Preview" className="preview-image" />
                    </div>
                ) : (
                    <div className="empty-content-image">
                        <div className="empty-item">
                            <FontAwesomeIcon size={'6x'} icon={faImage} />
                        </div>
                        <div className="empty-item">
                            <span>{title}</span>
                        </div>
                    </div>
                )}
            </div>
            {
                previewImage ?
                    <div className="controls">
                        <Button
                            onClick={e => {
                                setPreviewImage(null);
                                onImageUpload(null);
                            }}
                            label="Удалить"
                            severity="danger"
                            outlined />
                    </div> : ''
            }
        </div>
    );
}
