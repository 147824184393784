import {IResponse} from "../../types/server/IServer";
import makeServiceRequest, {THttpMethod} from "../makeServiceRequest";
import {IGlobalSetting, IGlobalSettingRequest} from "../../types/setting/IGlobalSetting";
import {IStatCountNewUsers, IStatPlatform} from "../../types/statistics/IStatistics";

export async function serviceNewUsersList(): Promise<IResponse<IStatCountNewUsers[], null>> {
    const uri = '/admin/statistics/new-users';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IStatCountNewUsers[], null>(method, uri);
    } catch (error) {
        throw error;
    }
}

export async function servicePlatform(): Promise<IResponse<IStatPlatform, null>> {
    const uri = '/admin/statistics/platforms';
    const method: THttpMethod = 'get';

    try {
        return await makeServiceRequest<IStatPlatform, null>(method, uri);
    } catch (error) {
        throw error;
    }
}
