import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILanguage} from "../../../types/lang/ILanguage";
import {modeEnum} from "../../../enums/modeEnum";
import i18n from "i18next";
import {getLanguages, getLocalLang} from "../../../helpers/languageHelper";

interface IAppState {
    languagesStore: ILanguage[],
    selectedLanguageStore: ILanguage | null,
    selectedModeStore: modeEnum,
}

const initialState: IAppState = {
    languagesStore: [],
    selectedLanguageStore: getLocalLang(),
    selectedModeStore: localStorage.getItem('mode') as modeEnum | null || modeEnum.Dark,
}

initialState.languagesStore = getLanguages();

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSelectedLanguageStore(state, action: PayloadAction<ILanguage>) {
            const lang = action.payload;
            state.selectedLanguageStore = lang;
            localStorage.setItem('lang', lang.code);
            i18n.changeLanguage(lang.code);
        },
        setSelectedModeStore(state, action: PayloadAction<modeEnum>) {
            const mode = action.payload;
            localStorage.setItem('mode', mode);
            state.selectedModeStore = action.payload;
        }
    }
});

export const appSliceActions = appSlice.actions;
export const appSliceReducer = appSlice.reducer;
