import React from "react";
import {Menubar} from "primereact/menubar";
import {MenuBarStart} from "./components/menuBarElements/MenuBarStart";
import {MenuBarEnd} from "./components/menuBarElements/MenuBarEnd";

export function MenuTop() {
    return (
        <header className={'header-top'}>
            <div className="card">
                <Menubar model={[]} start={MenuBarStart} end={MenuBarEnd}/>
            </div>
        </header>
    );
}
