import React, {FC, useEffect, useState} from "react";
import './translation.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {Input} from "../../ui/form/input/Input";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {Textarea} from "../../ui/form/textarea/Textarea";
import {IError} from "../../../types/IError";

interface itemsProps {
    code: string,
    image: string,
    nameLang: string,
    value: string,
    valueDescription?: string,
    isDisable: boolean,
    isProcessing: boolean,
    onHandleBlur: (e: React.ChangeEvent<HTMLInputElement>, code: string) => void,
    onHandleDescBlur?: (e: React.FocusEvent<HTMLTextAreaElement>, code: string) => void,
    error?: IError | null,
    errorDesc?: IError | null,
}

export const TranslationName: FC<itemsProps> = (props): JSX.Element => {
    const {code, image, nameLang, value, valueDescription, isDisable, isProcessing, onHandleBlur, onHandleDescBlur, error, errorDesc} = props;
    const [valueName, setValueName] = useState<string>('');
    const [valueDesc, setValueDesc] = useState<string>('');

    useEffect(() => {
        setValueName(value);
        setValueDesc(valueDescription || '');
    }, [value, valueDescription]);

    return (
        <Fieldset key={code} className={'fieldset translation-fieldset'} toggleable legend={<div className={'translation-fieldset-legend'}>
            <img src={image} alt={nameLang}/>
            <span>{nameLang}</span>
        </div>}>
            <div className="translations">
                <div className="translation-item">
                    <div className="translation-content">
                        <Input
                            title={'Введите перевод (наименование) *'}
                            value={valueName}
                            disabled={isDisable}
                            onHandleBlur={e => onHandleBlur(e, code)}
                            error={error}
                            onHandleChange={e => setValueName(e.target.value)} />
                        <Button icon={<FontAwesomeIcon
                            width={20}
                            height={20}
                            icon={faLanguage}
                        />}/>
                    </div>
                </div>
                {
                    onHandleDescBlur ?
                        <div className="translation-item">
                            <div className="translation-content">
                                <Textarea
                                    title={'Введите перевод (описание) *'}
                                    value={valueDesc}
                                    disabled={isDisable}
                                    onHandleBlur={e => onHandleDescBlur!(e, code)}
                                    onHandleChange={e => setValueDesc(e.target.value)}
                                    error={errorDesc}
                                />
                                <Button icon={<FontAwesomeIcon
                                    width={20}
                                    height={20}
                                    icon={faLanguage}
                                />}/>
                            </div>
                        </div> : ''
                }
            </div>
        </Fieldset>

    );
}
